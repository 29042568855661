import React, { useState } from "react";
import { AddMaterialFamily as DomainAddMaterialFamily, LoadMaterialClassificationList } from "@/domain/usecase";
import { Button, Modal, FormControl, Input, Messages, ProgressIndicator, Select } from "fiorde-fe-components";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import { makeSelectValues } from "@/presentation/utils";
import {
  PREFIX_REGISTER_ADDMATERIALFAMILY_BUTTON_OPEN,
  PREFIX_REGISTER_ADDMATERIALFAMILY_MODAL,
  PREFIX_REGISTER_ADDMATERIALFAMILY_BUTTON_CLOSE,
  PREFIX_REGISTER_ADDMATERIALFAMILY_BUTTON_SUBMIT,
  PREFIX_REGISTER_ADDMATERIALFAMILY_FORMCONTROL_INPUTNAME,
  PREFIX_REGISTER_ADDMATERIALFAMILY_INPUT_NAME,
  PREFIX_REGISTER_ADDMATERIALFAMILY_FORMCONTROL_SELECTMATERIALCLASSIFICATION,
} from "@/ids";

type AddMaterialFamilyProps = {
  addMaterialFamily: DomainAddMaterialFamily
  loadMaterialClassificationList: LoadMaterialClassificationList
}

export function AddMaterialFamily({ addMaterialFamily, loadMaterialClassificationList }: AddMaterialFamilyProps) {
  const queryClient = useQueryClient()
  const [isLoading, setLoading] = useState(false)
  const [name, setname] = useState("")
  const [materialClassification, setMaterialClassification] = useState(null)
  const [open, setOpen] = useState(false)
  const [responseMessage, setResponseMessage] = useState({
    message: "",
    severity: ""
  })
  const { data: materialClassificationList } = useQuery<any>({
    queryKey: ['material-classification'],
    queryFn: async () => await loadMaterialClassificationList.load(),
    enabled: open
  })

  function handleSubmit (): void {
    setLoading(true)
    addMaterialFamily.add({
      name,
      materialClassification: { 
        id: materialClassification,
      },
      isActive: true
    })
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ["material-family-list"] })
        setResponseMessage({
          message: t("components.add-material-family.response-success-message"),
          severity: "success"
        })
        handleClose()
      })
      .catch(err => {
        setResponseMessage({
          message: err?.message,
          severity: "error"
        })
      })
      .finally((() => {
        setLoading(false)
      }))
  }

  function handleOpen() {
    setOpen(true)
  }

  function handleClose() {
    setname("")
    setMaterialClassification([])
    setOpen(false)
  }

  return (
    <>
      <ProgressIndicator
        visible={isLoading}
        content={t("components.progress-indicator.content")}
      />
      <Button
        id={PREFIX_REGISTER_ADDMATERIALFAMILY_BUTTON_OPEN}
        icon="add"
        data-testid="open-button"
        onAction={() => handleOpen()}
        text={t("components.add-material-family.open-button")}
      />
      <Modal
        id={PREFIX_REGISTER_ADDMATERIALFAMILY_MODAL}
        width={600}
        title={t("components.add-material-family.open-button")}
        isOpen={open}
        onClose={handleClose}
        actions={[
          <Button
            id={PREFIX_REGISTER_ADDMATERIALFAMILY_BUTTON_CLOSE}
            data-testid="close-button"
            backgroundGreen={false}
            onAction={() => handleClose()}
            text={t("components.add-material-family.close-button")}
          />,
          <Button
            id={PREFIX_REGISTER_ADDMATERIALFAMILY_BUTTON_SUBMIT}
            data-testid="submit-button"
            onAction={() => handleSubmit()}
            text={t("components.add-material-family.submit-button")}
          />
        ]}
      >
        <FormControl 
          id={PREFIX_REGISTER_ADDMATERIALFAMILY_FORMCONTROL_INPUTNAME}
          label={t("components.add-material-family.input-label")}>
          <Input
            inputProps={{ "data-testid": "name" }}
            id={PREFIX_REGISTER_ADDMATERIALFAMILY_INPUT_NAME}
            onChange={(event: any) => setname(event.target.value)}
            value={name}
            placeholder={t("components.add-material-family.input-placeholder")}
          />
        </FormControl>
        <FormControl 
          id={PREFIX_REGISTER_ADDMATERIALFAMILY_FORMCONTROL_SELECTMATERIALCLASSIFICATION}
          label={t("components.add-material-family.select-label")}>
          <Select
            name='materialClassification'
            options={makeSelectValues({
              label: 'name',
              value: 'id',
              array: materialClassificationList
            })}
            onChange={(value) => setMaterialClassification(value)}
            value={materialClassification}
            placeholder={t("components.add-material-family.select-placeholder")}
          />
        </FormControl>
      </Modal>
      {responseMessage.message && (
        <Messages
          message={responseMessage.message}
          severity={responseMessage.severity}
          closable
          closeAlert={()=> setResponseMessage({
            message: "",
            severity: ""
          })}
        />
      )}
    </>
  );
}
