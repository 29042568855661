import { AccessDeniedError, UnexpectedError } from "@/domain/errors";
import { HttpClient, HttpStatusCode } from "@/domain/protocols/http";
import { LoadVehicleComposition } from "@/domain/usecase";
import { RemoteLoadVehicleCompositionDTO } from "../models";

export class RemoteLoadVehicleComposition implements LoadVehicleComposition {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<LoadVehicleComposition.Model>
  ) {}

  async load(id: number): Promise<LoadVehicleComposition.Model> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${id}`,
      method: "get",
    });
    const LoadVehicleComposition = httpResponse.body;
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return LoadVehicleComposition;
      case HttpStatusCode.forbidden:
        throw new AccessDeniedError();
      default:
        throw new UnexpectedError();
    }
  }
}

export namespace RemoteLoadVehicleComposition {
  export type Model = RemoteLoadVehicleCompositionDTO;
}
