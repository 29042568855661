

import { AccessDeniedError, UnexpectedError } from '@/domain/errors'
import { HttpClient, HttpStatusCode } from '@/domain/protocols/http'
import { LoadMaterialFamily } from '@/domain/usecase'
import { RemoteLoadMaterialFamilyDTO } from '../models'

export class RemoteLoadMaterialFamily implements LoadMaterialFamily {
  constructor (
    private readonly url: string,
    private readonly httpClient: HttpClient<LoadMaterialFamily.Model>
  ) {}

  async load (id: number): Promise<LoadMaterialFamily.Model> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${id}`,
      method: 'get'
    })
    const LoadMaterialFamily = httpResponse.body
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return LoadMaterialFamily
      case HttpStatusCode.forbidden: throw new AccessDeniedError()
      default: throw new UnexpectedError()
    }
  }
}

export namespace RemoteLoadMaterialFamily {
  export type Model = RemoteLoadMaterialFamilyDTO
}
