import React from 'react'
import { Row } from '../row/row'
import {
  FormControl,
  Input,
  Select,
  Switch
} from "fiorde-fe-components"
import { makeSelectValues } from '@/presentation/utils/arrays'
import { addClassification } from './business-partner-helpers'
import { t } from 'i18next'
import {
  PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_INPUT_SIMPLENAME,
  PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_INPUT_CORPORATENAME,
  PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_INPUT_DOCUMENT,
  PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_INPUT_REFERENCE
} from '@/ids'

export function BusinessPartnerForm({
  values,
  onChange,
  classificationList,
  documentTypeList,
  countriesList,
  statesList,
  citiesList
}) {
  return (
    <>
      <FormControl label={t("components.business-partner-form.add-classification-label")}>
        <Select
          name='classification'
          options={makeSelectValues({
            label: 'name',
            value: 'id',
            array: classificationList
          })}
          onChange={(value) => addClassification(values, value, onChange)}
          placeholder={t("components.business-partner-form.add-classification-placeholder")}
          multiple
          value={values?.classifications?.map(item => item?.classification?.id)}
        />
        </FormControl>
        <FormControl label={t("components.business-partner-form.simple-name-label")}>
          <Input
            inputProps={{ "data-testid": "simpleName" }}
            id={PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_INPUT_SIMPLENAME}
            onChange={(event: any) => onChange({...values, simpleName: event.target.value})}
            value={values?.simpleName}
            placeholder={t("components.business-partner-form.simple-name-placeholder")}
          />
        </FormControl>
        <FormControl label={t("components.business-partner-form.corporate-name-label")}>
          <Input
            inputProps={{ "data-testid": "corporateName" }}
            id={PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_INPUT_CORPORATENAME}
            onChange={(event: any) => onChange({...values, corporateName: event.target.value})}
            value={values?.corporateName}
            placeholder={t("components.business-partner-form.corporate-name-placeholder")}
          />
        </FormControl>
        <Row gridTemplateColumns='1fr 1fr'>
          <FormControl label={t("components.business-partner-form.document-type-label")}>
          <Select
            name='classification'
            options={makeSelectValues({
              label: 'name',
              value: 'id',
              array: documentTypeList
            })}
            onChange={(value) => onChange({...values, documentType: { id: value }})}
            value={values?.documentType?.id}
            placeholder={t("components.business-partner-form.document-type-placeholder")}
          />
          </FormControl>
          <FormControl label={t("components.business-partner-form.document-label")}>
            <Input
              inputProps={{ "data-testid": "document" }}
              id={PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_INPUT_DOCUMENT}
              onChange={(event: any) => onChange({...values, document: event.target.value})}
              value={values?.document}
              placeholder={t("components.business-partner-form.document-placeholder")}
            />
          </FormControl>
        </Row>
        <Row gridTemplateColumns='1fr 1fr'>
          <FormControl label={t("components.business-partner-form.reference-label")}>
            <Input
              inputProps={{ "data-testid": "reference" }}
              id={PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_INPUT_REFERENCE}
              onChange={(event: any) => onChange({...values, reference: event.target.value})}
              value={values?.reference}
              placeholder={t("components.business-partner-form.reference-placeholder")}
            />
          </FormControl>
          <FormControl label={t("components.business-partner-form.head-office-label")}>
            <Switch
              label={values?.isHeadOffice
                ? t("components.business-partner-form.head-office-true")
                : t("components.business-partner-form.head-office-false")}
              checked={values?.isHeadOffice}
              onChange={() => onChange({...values, isHeadOffice: !values?.isHeadOffice})}
            />
          </FormControl>
        </Row>
        <Row gridTemplateColumns='1fr 1fr 1fr' gap="16px">
        <FormControl label={t("components.business-partner-form.country-label")}>
          <Select
            name='country'
            options={makeSelectValues({
              label: 'name',
              value: 'id',
              array: countriesList
            })}
            onChange={(value) => onChange({ ...values, countryId: value, stateId: null, cityId: null })}
            value={values?.countryId}
            placeholder={t("components.business-partner-form.country-placeholder")}
          />
        </FormControl>
        <FormControl label={t("components.business-partner-form.state-label")}>
          <Select
            name='state'
            options={makeSelectValues({
              label: 'name',
              value: 'id',
              array: statesList
            })}
            onChange={(value) => onChange({ ...values, stateId: value, cityId: null })}
            value={values?.stateId}
            placeholder={t("components.business-partner-form.state-placeholder")}
          />
        </FormControl>
        <FormControl label={t("components.business-partner-form.city-label")}>
          <Select
            name='city'
            options={makeSelectValues({
              label: 'name',
              value: 'id',
              array: citiesList
            })}
            onChange={(value) => onChange({ ...values, cityId: value })}
            value={values?.cityId}
            placeholder={t("components.business-partner-form.city-placeholder")}
          />
        </FormControl>
      </Row>
    </>
  )
}