import React, { useContext } from 'react'
import { ProposalContext, ProposalProps } from '../../pages/NewProposal/context/ProposalContext'
import { ProfitValue, TotalContainer, UpperContainer, LowerContainer, ProfitContainer, ProfitLabel, PercentageLabel, PercentageCard } from './style'
import { I18n } from 'react-redux-i18n'
import PositiveProfitIcon from '../../../application/icons/PositiveProfitIcon'
import { convertToDecimal } from '../../pages/Tariff/helpers'
import { CostTypes } from '../../../application/enum/costEnum'
import FormatNumber from '../../../application/utils/formatNumber'

const TotalHeader = (): JSX.Element => {
  const { proposal }: ProposalProps = useContext(ProposalContext)

  const isValueFilled = (value: string | number): boolean => {
    return value !== '' && value !== '0,00' && value !== 0
  }

  const calculateProfitPercentage = (): any => {
    const total = proposal?.costs as any[]
    const freightCosts = total.filter((cost) => cost.costType === CostTypes.Freight)
    const output = freightCosts.reduce((acc, { idCurrencyPurchase, idCurrencySale, valuePurchase, valueSale }) => {
      const totalSale = Number(valueSale)
      const totalPurchase = Number(valuePurchase)
      if (idCurrencyPurchase === idCurrencySale && isValueFilled(valuePurchase) && isValueFilled(valueSale)) {
        const index = acc.findIndex(item => item.idCurrency === idCurrencySale)
        index === -1 ? acc.push({ idCurrency: idCurrencySale, total: (totalSale - totalPurchase) / totalPurchase * 100 }) : acc[index].total = Number(acc[index].total) + (totalSale - totalPurchase) / totalPurchase * 100
      }
      return acc
    }, [])
    return output
  }

  const calculateProfit = (): string => {
    const total = proposal?.totalCosts as any[]
    const output = total.reduce((acc, { idCurrency, valueTotalPurchase, valueTotalSale, costType }) => {
      const totalSale = valueTotalSale ? Number(valueTotalSale) : 0
      const totalPurchase = valueTotalPurchase ? Number(valueTotalPurchase) : 0
      const index = acc.findIndex(item => item.idCurrency === idCurrency)
      if (costType === CostTypes.Tariff) {
        index === -1 ? acc.push({ idCurrency, total: totalSale }) : acc[index].total = Number(acc[index].total) + totalSale
      } else {
        index === -1 ? acc.push({ idCurrency, total: totalSale - totalPurchase }) : acc[index].total = Number(acc[index].total) + totalSale - totalPurchase
      }
      return acc
    }, [])
    let result = ''
    if (total) {
      output.forEach((value, index) => {
        result += ` ${String(value.idCurrency)} ${convertToDecimal(value.total)} ${output.length - 1 === index ? '' : '+'}`
      })
    }
    return result
  }

  const proposalTotalSale = (): string => {
    const total = proposal?.totalCosts as any[]
    const output = total.reduce((acc, { idCurrency, valueTotalSale }) => {
      const index = acc.findIndex(item => item.idCurrency === idCurrency)
      if (valueTotalSale !== null) {
        index === -1 ? acc.push({ idCurrency, valueTotalSale: valueTotalSale }) : acc[index].valueTotalSale = Number(acc[index].valueTotalSale) + Number(valueTotalSale)
      }
      return acc
    }, [])
    let result = ''
    if (total) {
      output.forEach((value, index) => {
        result += ` ${String(value.idCurrency)} ${convertToDecimal(value.valueTotalSale)} ${output.length - 1 === index ? '' : '|'}`
      })
    }
    return result
  }

  return (
    <TotalContainer>
      <UpperContainer>
        <div>
          {I18n.t('pages.newProposal.profit')}
          <ProfitValue>{calculateProfit()}</ProfitValue>
        </div>
        <div>
          {I18n.t('pages.newProposal.totalProposal')}
          <span style={{ marginLeft: '20px' }}>{proposalTotalSale()}</span>
        </div>
      </UpperContainer>
      <LowerContainer>
        <ProfitContainer>
          <ProfitLabel>
            {I18n.t('pages.newProposal.percentageProfit')}
            {calculateProfitPercentage().map(({ idCurrency, total }) => {
              if (idCurrency) {
                return (
                  <PercentageCard color={total < 0 ? 'red' : 'normal'} key={idCurrency}>
                    {total < 0 ? <div style={{ transform: 'rotate(180deg)' }}><PositiveProfitIcon /></div> : <div><PositiveProfitIcon /></div>}
                    <PercentageLabel>{FormatNumber.convertNumberToString(total)}%</PercentageLabel>
                  </PercentageCard>
                )
              } else {
                return null
              }
            })}

          </ProfitLabel>
        </ProfitContainer>
      </LowerContainer>
    </TotalContainer>
  )
}

export default TotalHeader
