import React, { useEffect, useState } from "react";
import {
  UpdateSpot as DomainUpdateSpot,
  LoadSpot,
  LoadSpotStatusList,
  LoadVehicleCompositionList,
} from "@/domain/usecase";
import {
  Button,
  Modal,
  FormControl,
  Messages,
  Input,
  IconButton,
  ProgressIndicator,
  Select,
} from "fiorde-fe-components";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { EditIcon } from "../icons";
import { t } from "i18next";
import { makeSelectValues } from "@/presentation/utils";
import {
  PREFIX_REGISTER_UPDATESPOT_ICONBUTTON_OPENTOOLTIP,
  PREFIX_REGISTER_UPDATESPOT_MODAL,
  PREFIX_REGISTER_UPDATESPOT_BUTTON_CLOSE,
  PREFIX_REGISTER_UPDATESPOT_BUTTON_SUBMIT,
  PREFIX_REGISTER_UPDATESPOT_FORMCONTROL_INPUTNAME,
  PREFIX_REGISTER_UPDATESPOT_INPUT_NAME,
  PREFIX_REGISTER_UPDATESPOT_FORMCONTROL_INPUTLOCATION,
  PREFIX_REGISTER_UPDATESPOT_INPUT_LOCATION,
  PREFIX_REGISTER_UPDATESPOT_FORMCONTROL_SELECTVEHICLECOMPOSITION,
  PREFIX_REGISTER_UPDATESPOT_FORMCONTROL_SELECTSPOTSTATUS,
} from "@/ids";

type UpdateSpotProps = {
  updateSpot: DomainUpdateSpot;
  loadSpot: LoadSpot;
  id: number;
  loadSpotStatusList: LoadSpotStatusList;
  loadVehicleCompositionList: LoadVehicleCompositionList
};

export function UpdateSpot({
  loadSpot,
  updateSpot,
  id,
  loadSpotStatusList,
  loadVehicleCompositionList
}: UpdateSpotProps) {
  const queryClient = useQueryClient();
  const [name, setname] = useState("");
  const [location, setlocation] = useState("");
  const [spotStatus, setSpotStatus] = useState<null | number>(null);
  const [vehicleCompositions, setVehicleCompositions] = useState([]);
  const [open, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState({
    message: "",
    severity: "",
  });

  const { data: spotStatusList } = useQuery<any>({
    queryKey: ["spot-status"],
    queryFn: async () => await loadSpotStatusList.load(),
    enabled: open,
  });

  const { data: vehicleCompositionList } = useQuery<any>({
    queryKey: ["vehicle-composition-all"],
    queryFn: async () => await loadVehicleCompositionList.load(),
    enabled: open,
  });

  const { data } = useQuery<any>({
    queryKey: [`spot-${id}`],
    queryFn: async () => await loadSpot.load(id),
    enabled: open,
  });

  function handleSubmit(): void {
    setLoading(true);
    updateSpot
      .update(id, {
        name,
        location,
        spotStatus: {
          id: spotStatus,
        },
        vehicleCompositions: vehicleCompositions?.map(vehicleComposition => ({ id: vehicleComposition })),
        isActive: data?.isActive,
      })
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ["spot-list"] });
        handleClose();
        setResponseMessage({
          message: t("components.update-spot.response-success-message"),
          severity: "success",
        });
      })
      .catch((err) => {
        setResponseMessage({
          message: err?.message,
          severity: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  useEffect(() => {
    if (open) {
      queryClient.invalidateQueries({ queryKey: [`spot-${id}`] });
    }
  }, [open]);

  useEffect(() => {
    setname(data?.name);
    setlocation(data?.location);
    setSpotStatus(data?.spotStatus?.id);
    setVehicleCompositions(data?.vehicleCompositions?.map(vehicleComposition => vehicleComposition?.id))
  }, [data]);

  return (
    <>
      <ProgressIndicator
        visible={isLoading}
        content={t("components.progress-indicator.content")}
      />
      <IconButton
        id={PREFIX_REGISTER_UPDATESPOT_ICONBUTTON_OPENTOOLTIP}
        data-testid="open-button"
        onClick={handleOpen}
        tooltip={t("components.update-spot.open-button-tooltip")}
      >
        <EditIcon />
      </IconButton>
      <Modal
        id={PREFIX_REGISTER_UPDATESPOT_MODAL}
        width={600}
        title={t("components.update-spot.title")}
        isOpen={open}
        onClose={handleClose}
        actions={[
          <Button
            id={PREFIX_REGISTER_UPDATESPOT_BUTTON_CLOSE}
            data-testid="close-button"
            backgroundGreen={false}
            onAction={() => handleClose()}
            text={t("components.update-spot.close-button")}
          />,
          <Button
            id={PREFIX_REGISTER_UPDATESPOT_BUTTON_SUBMIT}
            data-testid="submit-button"
            onAction={() => handleSubmit()}
            text={t("components.update-spot.submit-button")}
          />,
        ]}
      >
        <FormControl 
          id={PREFIX_REGISTER_UPDATESPOT_FORMCONTROL_INPUTNAME}
          label={t("components.update-spot.input-label-name")}>
          <Input
            inputProps={{ "data-testid": "name" }}
            id={PREFIX_REGISTER_UPDATESPOT_INPUT_NAME}
            onChange={(event: any) => setname(event.target.value)}
            value={name}
            placeholder={t("components.update-spot.input-placeholder")}
          />
        </FormControl>
        <FormControl 
          id={PREFIX_REGISTER_UPDATESPOT_FORMCONTROL_INPUTLOCATION}
          label={t("components.add-spot.input-label-location")}>
          <Input
            inputProps={{ "data-testid": "location" }}
            id={PREFIX_REGISTER_UPDATESPOT_INPUT_LOCATION}
            onChange={(event: any) => setlocation(event.target.value)}
            value={location}
            placeholder={t("components.add-spot.input-placeholder-location")}
          />
        </FormControl>
        <FormControl 
          id={PREFIX_REGISTER_UPDATESPOT_FORMCONTROL_SELECTVEHICLECOMPOSITION}
          label={t("components.update-spot.vehicle-composition-label")}>
          <Select
            name="vehicleComposition"
            options={makeSelectValues({
              label: "name",
              value: "id",
              array: vehicleCompositionList,
            })}
            onChange={(value) => setVehicleCompositions(value)}
            value={vehicleCompositions}
            multiple
            placeholder={t("components.update-spot.vehicle-composition-placeholder")}
          />
        </FormControl>
        <FormControl 
          id={PREFIX_REGISTER_UPDATESPOT_FORMCONTROL_SELECTSPOTSTATUS}
          label={t("components.update-spot.spot-status-label")}>
          <Select
            name="spotStatus"
            options={makeSelectValues({
              label: "name",
              value: "id",
              array: spotStatusList,
            })}
            onChange={(value) => setSpotStatus(value)}
            value={spotStatus}
            placeholder={t("components.update-spot.spot-status-placeholder")}
          />
        </FormControl>
      </Modal>
      {responseMessage.message && (
        <Messages
          message={responseMessage.message}
          severity={responseMessage.severity}
          closable
          closeAlert={() =>
            setResponseMessage({
              message: "",
              severity: "",
            })
          }
        />
      )}
    </>
  );
}
