import React, { useState } from "react";
import { AddVehicleType as DomainAddVehicleType } from "@/domain/usecase";
import { Button, Modal, FormControl, Input, Messages, ProgressIndicator } from "fiorde-fe-components";
import { useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import { 
  PREFIX_VEHICLETYPE_ADDVEHICLETYPE_BUTTON_OPEN,
  PREFIX_VEHICLETYPE_ADDVEHICLETYPE_BUTTON_CLOSE,
  PREFIX_VEHICLETYPE_ADDVEHICLETYPE_BUTTON_SUBMIT,
  PREFIX_VEHICLETYPE_ADDVEHICLETYPE_INPUT_NAME


} from "@/ids";

type AddVehicleTypeProps = {
  addVehicleType: DomainAddVehicleType;
};

export function AddVehicleType({ addVehicleType }: AddVehicleTypeProps) {
  const queryClient = useQueryClient()
  const [isLoading, setLoading] = useState(false)
  const [txVehicleType, setTxVehicleType] = useState("")
  const [open, setOpen] = useState(false)
  const [responseMessage, setResponseMessage] = useState({
    message: "",
    severity: "",
  });

  function handleSubmit (): void {
    setLoading(true)
    addVehicleType.add({ txVehicleType, isActive: true })
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ["vehicle-type-list"] });
        handleClose();
        setResponseMessage({
          message: t("components.add-vehicle-type.response-success-message"),
          severity: "success"
        })
      })
      .catch((err) => {
        setResponseMessage({
          message: err?.message,
          severity: "error"
        })
      })
      .finally((() => {
        setLoading(false)
      }))
  }

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setTxVehicleType("");
    setResponseMessage({
      message: "",
      severity: "",
    });
    setOpen(false);
  }

  return (
    <>
      <ProgressIndicator
        visible={isLoading}
        content={t("components.progress-indicator.content")}
      />
      <Button
        id={PREFIX_VEHICLETYPE_ADDVEHICLETYPE_BUTTON_OPEN}
        icon="add"
        data-testid="open-button"
        onAction={() => handleOpen()}
        text={t("components.add-vehicle-type.open-button")}
      />
      <Modal
        data-testid="modal-add-vehicle-type"
        width={600}
        title={t("components.add-vehicle-type.open-button")}
        isOpen={open}
        onClose={handleClose}
        actions={[
          <Button
            id={PREFIX_VEHICLETYPE_ADDVEHICLETYPE_BUTTON_CLOSE}
            data-testid="close-button"
            backgroundGreen={false}
            onAction={() => handleClose()}
            text={t("components.add-vehicle-type.close-button")}
          />,
          <Button
            id={PREFIX_VEHICLETYPE_ADDVEHICLETYPE_BUTTON_SUBMIT}
            data-testid="submit-button"
            onAction={() => handleSubmit()}
            text={t("components.add-vehicle-type.submit-button")}
          />
        ]}
      >
        <FormControl 
          label={t("components.add-vehicle-type.name-label")}>
          <Input
            inputProps={{ "data-testid": "txVehicleType" }}
            id={PREFIX_VEHICLETYPE_ADDVEHICLETYPE_INPUT_NAME}
            onChange={(event: any) => setTxVehicleType(event.target.value)}
            value={txVehicleType}
            placeholder={t("components.add-vehicle-type.name-placeholder")}
          />
        </FormControl>
      </Modal>
      {responseMessage.message && (
        <Messages
          message={responseMessage.message}
          severity={responseMessage.severity}
          closable
          closeAlert={() =>
            setResponseMessage({
              message: "",
              severity: "",
            })
          }
        />
      )}
    </>
  );
}
