import React, { useEffect, useState } from "react";
import { UpdateMaterialFamily as DomainUpdateMaterialFamily, LoadMaterialClassificationList, LoadMaterialFamily } from "@/domain/usecase";
import { Button, Modal, FormControl, Messages, Input, IconButton, ProgressIndicator, Select } from "fiorde-fe-components";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { EditIcon } from "../icons";
import { t } from "i18next";
import { makeSelectValues } from "@/presentation/utils";
import {
  PREFIX_REGISTER_UPDATEMATERIALFAMILY_ICONBUTTON_OPENTOOLTIP,
  PREFIX_REGISTER_UPDATEMATERIALFAMILY_MODAL,
  PREFIX_REGISTER_UPDATEMATERIALFAMILY_BUTTON_CLOSE,
  PREFIX_REGISTER_UPDATEMATERIALFAMILY_BUTTON_SUBMIT,
  PREFIX_REGISTER_UPDATEMATERIALFAMILY_FORMCONTROL_INPUTNAME,
  PREFIX_REGISTER_UPDATEMATERIALFAMILY_INPUT_NAME,
  PREFIX_REGISTER_UPDATEMATERIALFAMILY_FORMCONTROL_SELECTMATERIALCLASSIFICATION,
} from "@/ids";

type UpdateMaterialFamilyProps = {
  updateMaterialFamily: DomainUpdateMaterialFamily
  loadMaterialFamily: LoadMaterialFamily
  id: number
  loadMaterialClassificationList: LoadMaterialClassificationList
}

export function UpdateMaterialFamily({ loadMaterialFamily, updateMaterialFamily, id, loadMaterialClassificationList }: UpdateMaterialFamilyProps) {
  const queryClient = useQueryClient()
  const [name, setname] = useState("")
  const [materialClassification, setMaterialClassification] = useState(null)
  const [open, setOpen] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [responseMessage, setResponseMessage] = useState({
    message: "",
    severity: ""
  })
  const { data: materialClassificationList } = useQuery<any>({
    queryKey: ['material-classification'],
    queryFn: async () => await loadMaterialClassificationList.load(),
    enabled: open
  })

  const { data } = useQuery<any>({
    queryKey: [`material-family-${id}`],
    queryFn: async () => await loadMaterialFamily.load(id),
    enabled: open
  })

  function handleSubmit (): void {
    setLoading(true)
    updateMaterialFamily.update(id, {
      name,
      materialClassification: { 
        id: materialClassification 
      },
      isActive: data?.isActive
    })
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ['material-family-list'] })
        handleClose()
        setResponseMessage({
          message: t("components.update-material-family.response-success-message"),
          severity: "success"
        })
      })
      .catch(err => {
        setResponseMessage({
          message: err?.message,
          severity: "error"
        })
      })
      .finally((() => {
        setLoading(false)
      }))
  }

  function handleOpen() {
    setOpen(true)
  }

  function handleClose() {
    setOpen(false)
  }

  useEffect(() => {
    if (open) {
      queryClient.invalidateQueries({ queryKey: [`material-family-${id}`]})
    }
  },[open])

  useEffect(() => {
    setname(data?.name)
    setMaterialClassification(data?.materialClassification?.id)
  }, [data])

  return (
    <>
      <ProgressIndicator
        visible={isLoading}
        content={t("components.progress-indicator.content")}
      />
      <IconButton
        id={PREFIX_REGISTER_UPDATEMATERIALFAMILY_ICONBUTTON_OPENTOOLTIP}
        data-testid="open-button"
        onClick={handleOpen}
        tooltip={t("components.update-material-family.open-button-tooltip")}
      >
        <EditIcon />
      </IconButton>
      <Modal
        id={PREFIX_REGISTER_UPDATEMATERIALFAMILY_MODAL}
        width={600}
        title={t("components.update-material-family.title")}
        isOpen={open}
        onClose={handleClose}
        actions={[
          <Button
            id={PREFIX_REGISTER_UPDATEMATERIALFAMILY_BUTTON_CLOSE}
            data-testid='close-button'
            backgroundGreen={false}
            onAction={() => handleClose()}
            text={t("components.update-material-family.close-button")}
          />,
          <Button
            id={PREFIX_REGISTER_UPDATEMATERIALFAMILY_BUTTON_SUBMIT}
            data-testid='submit-button'
            onAction={() => handleSubmit()}
            text={t("components.update-material-family.submit-button")}
          />
        ]}
      >
        <FormControl 
          id={PREFIX_REGISTER_UPDATEMATERIALFAMILY_FORMCONTROL_INPUTNAME}
          label={t("components.update-material-family.input-label")}>
          <Input
            inputProps={{ 'data-testid': 'name' }}
            id={PREFIX_REGISTER_UPDATEMATERIALFAMILY_INPUT_NAME}
            onChange={(event: any) => setname(event.target.value)}
            value={name}
            placeholder={t("components.update-material-family.input-placeholder")}
          />
        </FormControl>
        <FormControl 
          id={PREFIX_REGISTER_UPDATEMATERIALFAMILY_FORMCONTROL_SELECTMATERIALCLASSIFICATION}
          label={t("components.update-material-family.select-label")}>
        <Select
          name='materialClassification'
          options={makeSelectValues({
            label: 'name',
            value: 'id',
            array: materialClassificationList
          })}
          onChange={(value) => setMaterialClassification(value)}
          value={materialClassification}
        />
        </FormControl>
      </Modal>
      {responseMessage.message && (
        <Messages
          message={responseMessage.message}
          severity={responseMessage.severity}
          closable
          closeAlert={()=> setResponseMessage({
            message: "",
            severity: ""
          })}
        />
      )}
    </>
  );
}
