import React, { useEffect, useState } from "react";
import {
  UpdateDock as DomainUpdateDock,
  LoadDock,
  LoadDockStatusList,
  LoadVehicleCompositionList,
  LoadMaterialFamilyList,
  LoadMovementTypeList,
} from "@/domain/usecase";
import {
  Button,
  Modal,
  FormControl,
  Messages,
  Input,
  IconButton,
  ProgressIndicator,
  Select,
  Radio,
} from "fiorde-fe-components";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { EditIcon } from "../icons";
import { t } from "i18next";
import { makeSelectValues } from "@/presentation/utils";
import {
  PREFIX_REGISTER_UPDATEDOCK_ICONBUTTON_OPENTOOLTIP,
  PREFIX_REGISTER_UPDATEDOCK_MODAL,
  PREFIX_REGISTER_UPDATEDOCK_BUTTON_CLOSE,
  PREFIX_REGISTER_UPDATEDOCK_BUTTON_SUBMIT,
  PREFIX_REGISTER_UPDATEDOCK_FORMCONTROL_INPUTNAME,
  PREFIX_REGISTER_UPDATEDOCK_INPUT_NAME,
  PREFIX_REGISTER_UPDATEDOCK_FORMCONTROL_INPUTDESCRIPTION,
  PREFIX_REGISTER_UPDATEDOCK_INPUT_DESCRIPTION,
  PREFIX_REGISTER_UPDATEDOCK_FORMCONTROL_SELECTMATERIALFAMILY,
  PREFIX_REGISTER_UPDATEDOCK_FORMCONTROL_SELECTVEHICLECOMPOSITION,
  PREFIX_REGISTER_UPDATEDOCK_FORMCONTROL_SELECTMOVEMENTTYPE,
  PREFIX_REGISTER_UPDATEDOCK_FORMCONTROL_SELECTDOCKSTATUS,
} from "@/ids";

type UpdateDockProps = {
  updateDock: DomainUpdateDock;
  loadDock: LoadDock;
  id: number;
  loadDockStatusList: LoadDockStatusList;
  loadMaterialFamilyList: LoadMaterialFamilyList;
  loadVehicleCompositionList: LoadVehicleCompositionList
  loadMovementTypeList: LoadMovementTypeList
};

export function UpdateDock({
  loadDock,
  updateDock,
  id,
  loadDockStatusList,
  loadVehicleCompositionList,
  loadMaterialFamilyList,
  loadMovementTypeList
}: UpdateDockProps) {
  const queryClient = useQueryClient();
  const [name, setname] = useState("");
  const [description, setDescription] = useState("");
  const [dockStatus, setDockStatus] = useState<null | number>(null);
  const [vehicleCompositions, setVehicleCompositions] = useState([]);
  const [materialFamilies, setMaterialFamilies] = useState([]);
  const [movementTypes, setMovementTypes] = useState([]);
  const [open, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [responseMessage, setResponseMessage] = useState({
    message: "",
    severity: "",
  });

  const { data: dockStatusList } = useQuery<any>({
    queryKey: ["dock-status"],
    queryFn: async () => await loadDockStatusList.load(),
    enabled: open,
  });

  const { data: vehicleCompositionList } = useQuery<any>({
    queryKey: ["vehicle-composition-all"],
    queryFn: async () => await loadVehicleCompositionList.load(),
    enabled: open,
  });

  const { data: materialFamilyList } = useQuery<any>({
    queryKey: ["material-family-all"],
    queryFn: async () => await loadMaterialFamilyList.load(),
    enabled: open,
  });

  const { data: movementTypeList } = useQuery({
    queryKey: ["movement-type-all"],
    queryFn: async () => loadMovementTypeList.load(),
  });

  const { data } = useQuery<any>({
    queryKey: [`dock-${id}`],
    queryFn: async () => await loadDock.load(id),
    enabled: open,
  });

  function handleSubmit(): void {
    setLoading(true);
    updateDock
      .update(id, {
        name,
        description,
        dockStatus: {
          id: dockStatus,
        },
        vehicleCompositions: vehicleCompositions?.map(vehicleComposition => ({ id: vehicleComposition })),
        materialFamilies: materialFamilies?.map(materialFamily => ({ id: materialFamily })),
        movementTypes: movementTypes?.map(movementType => ({ id: movementType })),
        isActive: data?.isActive,
      })
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ["dock-list"] });
        handleClose();
        setResponseMessage({
          message: t("components.update-dock.response-success-message"),
          severity: "success",
        });
      })
      .catch((err) => {
        setResponseMessage({
          message: err?.message,
          severity: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  useEffect(() => {
    if (open) {
      queryClient.invalidateQueries({ queryKey: [`dock-${id}`] });
    }
  }, [open]);

  useEffect(() => {
    setname(data?.name);
    setDescription(data?.description);
    setDockStatus(data?.dockStatus?.id);
    setVehicleCompositions(data?.vehicleCompositions?.map(vehicleComposition => vehicleComposition?.id))
    setMaterialFamilies(data?.materialFamilies?.map(materialFamily => materialFamily?.id))
    setMovementTypes(data?.movementTypes?.map(movementType => movementType?.id))
  }, [data]);

  return (
    <>
      <ProgressIndicator
        visible={isLoading}
        content={t("components.progress-indicator.content")}
      />
      <IconButton
        id={PREFIX_REGISTER_UPDATEDOCK_ICONBUTTON_OPENTOOLTIP}
        data-testid="open-button"
        onClick={handleOpen}
        tooltip={t("components.update-dock.open-button-tooltip")}
      >
        <EditIcon />
      </IconButton>
      <Modal
        id={PREFIX_REGISTER_UPDATEDOCK_MODAL}
        width={600}
        title={t("components.update-dock.title")}
        isOpen={open}
        onClose={handleClose}
        actions={[
          <Button
            id={PREFIX_REGISTER_UPDATEDOCK_BUTTON_CLOSE}
            data-testid="close-button"
            backgroundGreen={false}
            onAction={() => handleClose()}
            text={t("components.update-dock.close-button")}
          />,
          <Button
            id={PREFIX_REGISTER_UPDATEDOCK_BUTTON_SUBMIT}
            data-testid="submit-button"
            onAction={() => handleSubmit()}
            text={t("components.update-dock.submit-button")}
          />,
        ]}
      >
        <FormControl 
          id={PREFIX_REGISTER_UPDATEDOCK_FORMCONTROL_INPUTNAME} 
          label={t("components.update-dock.input-label-name")}>
          <Input
            inputProps={{ "data-testid": "name" }}
            id={PREFIX_REGISTER_UPDATEDOCK_INPUT_NAME} 
            onChange={(event: any) => setname(event.target.value)}
            value={name}
            placeholder={t("components.update-dock.input-placeholder-name")}
          />
        </FormControl>
        <FormControl 
          id={PREFIX_REGISTER_UPDATEDOCK_FORMCONTROL_INPUTDESCRIPTION} 
          label={t("components.update-dock.input-label-description")}>
          <Input
            inputProps={{ "data-testid": "description" }}
            id={PREFIX_REGISTER_UPDATEDOCK_INPUT_DESCRIPTION} 
            onChange={(event: any) => setDescription(event.target.value)}
            value={description}
            placeholder={t("components.update-dock.input-placeholder-description")}
            multiline={true}
            rows={2}
          />
        </FormControl>
        <FormControl 
          id={PREFIX_REGISTER_UPDATEDOCK_FORMCONTROL_SELECTMATERIALFAMILY} 
          label={t("components.update-dock.material-family-label")}>
          <Select
            name="materialFamily"
            options={makeSelectValues({
              label: "name",
              value: "id",
              array: materialFamilyList,
            })}
            onChange={(value) => setMaterialFamilies(value)}
            value={materialFamilies}
            multiple
            placeholder={t("components.update-dock.material-family-placeholder")}
          />
        </FormControl>
        <FormControl 
          id={PREFIX_REGISTER_UPDATEDOCK_FORMCONTROL_SELECTVEHICLECOMPOSITION} 
          label={t("components.update-dock.vehicle-composition-label")}>
          <Select
            name="vehicleComposition"
            options={makeSelectValues({
              label: "name",
              value: "id",
              array: vehicleCompositionList,
            })}
            onChange={(value) => setVehicleCompositions(value)}
            value={vehicleCompositions}
            multiple
            placeholder={t("components.update-dock.vehicle-composition-placeholder")}
          />
        </FormControl>
        <FormControl 
          id={PREFIX_REGISTER_UPDATEDOCK_FORMCONTROL_SELECTMOVEMENTTYPE} 
          label={t("components.update-dock.movement-type-label")}>
          <Select
            name="movementType"
            options={makeSelectValues({
              label: "name",
              value: "id",
              array: movementTypeList,
            })}
            onChange={(value) => setMovementTypes(value)}
            value={movementTypes}
            multiple
            placeholder={t("components.update-dock.movement-type-placeholder")}
          />
        </FormControl>
        <FormControl 
          id={PREFIX_REGISTER_UPDATEDOCK_FORMCONTROL_SELECTDOCKSTATUS} 
          label={t("components.update-dock.dock-status-label")}>
          <Select
            name="dockStatus"
            options={makeSelectValues({
              label: "name",
              value: "id",
              array: dockStatusList,
            })}
            onChange={(value) => setDockStatus(value)}
            value={dockStatus}
            placeholder={t("components.update-dock.dock-status-placeholder")}
          />
        </FormControl>
      </Modal>
      {responseMessage.message && (
        <Messages
          message={responseMessage.message}
          severity={responseMessage.severity}
          closable
          closeAlert={() =>
            setResponseMessage({
              message: "",
              severity: "",
            })
          }
        />
      )}
    </>
  );
}
