import { AccessDeniedError, UnexpectedError } from "@/domain/errors";
import { HttpClient, HttpStatusCode } from "@/domain/protocols/http";
import { LoadSpot } from "@/domain/usecase";
import { RemoteLoadSpotDTO } from "../models";

export class RemoteLoadSpot implements LoadSpot {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<LoadSpot.Model>
  ) {}

  async load(id: number): Promise<LoadSpot.Model> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${id}`,
      method: "get",
    });
    const LoadSpot = httpResponse.body;
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return LoadSpot;
      case HttpStatusCode.forbidden:
        throw new AccessDeniedError();
      default:
        throw new UnexpectedError();
    }
  }
}

export namespace RemoteLoadSpot {
  export type Model = RemoteLoadSpotDTO;
}
