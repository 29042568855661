import { AccessDeniedError, UnexpectedError } from "@/domain/errors";
import { HttpClient, HttpStatusCode } from "@/domain/protocols/http";
import { LoadStep } from "@/domain/usecase";
import { RemoteLoadStepDTO } from "@/data/models";

export class RemoteLoadStep implements LoadStep {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<LoadStep.Model>
  ) {}

  async load(id: number): Promise<LoadStep.Model> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${id}`,
      method: "get",
    });
    const LoadStep = httpResponse.body;
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return LoadStep;
      case HttpStatusCode.forbidden:
        throw new AccessDeniedError();
      default:
        throw new UnexpectedError();
    }
  }
}

export namespace RemoteLoadStep {
  export type Model = RemoteLoadStepDTO;
}
