import { AccessDeniedError, UnexpectedError } from "@/domain/errors";
import { HttpClient, HttpStatusCode } from "@/domain/protocols/http";
import { LoadVehicleCompositionList } from "@/domain/usecase";
import { RemoteLoadVehicleCompositionDTO } from "../models";

export class RemoteLoadVehicleCompositionList implements LoadVehicleCompositionList {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<LoadVehicleCompositionList.Model>
  ) {}

  async load(): Promise<LoadVehicleCompositionList.Model> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: "get",
    });
    const LoadVehicleCompositionList = httpResponse.body;
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return LoadVehicleCompositionList;
      case HttpStatusCode.forbidden:
        throw new AccessDeniedError();
      default:
        throw new UnexpectedError();
    }
  }
}

export namespace RemoteLoadVehicleCompositionList {
  export type Model = RemoteLoadVehicleCompositionDTO[];
}
