import React from 'react'
import { Row } from '../row/row'
import {
  Button,
  FormControl,
  Input,
  Select,
} from "fiorde-fe-components"
import { makeSelectValues } from '@/presentation/utils/arrays'
import { disableAddForm, filteredAddressType } from './business-partner-helpers'
import { t } from 'i18next'
import { PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNERADDRESS_INPUT_ZIPCODE,
  PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNERADDRESS_INPUT_ADDRESSNUMBER,
  PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNERADDRESS_INPUT_ADDRESS,
  PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNERADDRESS_INPUT_NEIGHBORHOOD,
  PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNERADDRESS_INPUT_COMPLEMENT

 } from "@/ids"

export function BusinessPartnerAddressForm({
  addresses,
  values,
  onChange,
  addressTypeList,
  onSubmit,
  onClose,
  disabledCloseButton
}) {
  return (
    <>
      <Row gridTemplateColumns='1fr 2fr'>
        <FormControl label={t("components.business-partner-address-form.zip-code-label")}>
          <Input
            inputProps={{ "data-testid": "reference" }}
            id={PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNERADDRESS_INPUT_ZIPCODE}
            onChange={(event: any) => onChange({...values, postalCode: event.target.value})}
            value={values.postalCode}
            placeholder={t("components.business-partner-address-form.zip-code-placeholder")}
          />
        </FormControl>
        <FormControl label={t("components.business-partner-address-form.address-type-label")}>
          <Select
            name='classification'
            options={makeSelectValues({
              label: 'name',
              value: 'id',
              array: filteredAddressType(addressTypeList, addresses)
            })}
            onChange={(value) => onChange({...values, addressType: { id: value }})}
            placeholder={t("components.business-partner-address-form.address-type-placeholder")}
            value={values?.addressType?.id}
          />
        </FormControl>
      </Row>
      <Row gridTemplateColumns='2fr 1fr'>
        <FormControl label={t("components.business-partner-address-form.address-label")}>
          <Input
            inputProps={{ "data-testid": "reference" }}
            id={PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNERADDRESS_INPUT_ADDRESS}
            onChange={(event: any) => onChange({...values, name: event.target.value})}
            value={values.name}
            placeholder={t("components.business-partner-address-form.address-placeholder")}
          />
        </FormControl>
        <FormControl label={t("components.business-partner-address-form.address-number-label")}>
          <Input
            inputProps={{ "data-testid": "reference" }}
            id={PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNERADDRESS_INPUT_ADDRESSNUMBER}
            onChange={(event: any) => onChange({...values, number: event.target.value})}
            value={values.number}
            placeholder={t("components.business-partner-address-form.address-number-placeholder")}
          />
        </FormControl>
      </Row>
      <Row gridTemplateColumns='1fr 1fr'>
        <FormControl label={t("components.business-partner-address-form.neighborhood-label")}>
          <Input
            inputProps={{ "data-testid": "reference" }}
            id={PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNERADDRESS_INPUT_NEIGHBORHOOD}
            onChange={(event: any) => onChange({...values, neighborhood: event.target.value})}
            value={values.neighborhood}
            placeholder={t("components.business-partner-address-form.neighborhood-placeholder")}
          />
        </FormControl>
        <FormControl label={t("components.business-partner-address-form.complement-label")}>
          <Input
            inputProps={{ "data-testid": "reference" }}
            id={PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNERADDRESS_INPUT_COMPLEMENT}
            onChange={(event: any) => onChange({...values, complement: event.target.value})}
            value={values.complement}
            placeholder={t("components.business-partner-address-form.complement-placeholder")}
          />
        </FormControl>
      </Row>
      <Row gridTemplateColumns='1fr auto' justifyItems="end">
        <Button
          backgroundGreen={false}
          onAction={onClose}
          text="Fechar"
          disabled={disabledCloseButton}
        />
        <Button
          onAction={() => onSubmit(values)}
          disabled={disableAddForm(values)}
          text={values.index !== null ? "Editar endereço" : "Adicionar endereço"}
        />
      </Row>
    </>
  )
}