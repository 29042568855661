import React from 'react'
import {
  Button,
  FormControl,
  Input,
} from "fiorde-fe-components"
import { disableAddContactForm, disableAddForm } from './business-partner-helpers'
import { Row } from '../row/row';
import { t } from 'i18next';
import { PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNERCONTACT_INPUT_NAME,
  PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNERCONTACT_INPUT_PHONE,
  PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNERCONTACT_INPUT_EMAIL,
  PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNERCONTACT_BUTTON_SUBMIT,
  PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNERCONTACT_BUTTON_CLOSE
 } from '@/ids';

export function BusinessPartnerContactForm({
  values,
  onChange,
  onSubmit,
  onClose,
  disabledCloseButton
}) {
  return (
    <>
      <Row gridTemplateColumns='2fr 1fr'>
        <FormControl label={t("components.business-partner-contact-form.name-label")}>
          <Input
            inputProps={{ "data-testid": "contact" }}
            id={PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNERCONTACT_INPUT_NAME}
            onChange={(event) => onChange({...values, name: event.target.value})}
            value={values.name}
            placeholder={t("components.business-partner-contact-form.name-placeholder")}
          />
        </FormControl>
        <FormControl label={t("components.business-partner-contact-form.phone-label")}>
          <Input
            inputProps={{ "data-testid": "phone" }}
            id={PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNERCONTACT_INPUT_PHONE}
            onChange={(event) => onChange({...values, phone: event.target.value})}
            value={values.phone}
            placeholder={t("components.business-partner-contact-form.phone-placeholder")}
          />
        </FormControl>
      </Row>
      <Row gridTemplateColumns='1fr'>
        <FormControl label={t("components.business-partner-contact-form.email-label")}>
          <Input
            inputProps={{ "data-testid": "email" }}
            id={PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNERCONTACT_INPUT_EMAIL}
            onChange={(event) => onChange({...values, email: event.target.value})}
            value={values.email}
            placeholder={t("components.business-partner-contact-form.name-placeholder")}
          />
        </FormControl>
      </Row>
      <Row gridTemplateColumns='1fr auto' justifyItems="end">
        <Button
          id={PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNERCONTACT_BUTTON_CLOSE}
          backgroundGreen={false}
          onAction={onClose}
          text={t("components.business-partner-contact-form.close-button")}
          disabled={disabledCloseButton}
        />
        <Button
          id={PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNERCONTACT_BUTTON_SUBMIT}
          onAction={() => onSubmit(values)}
          disabled={disableAddContactForm(values)}
          text={values.index !== null
            ? t("components.business-partner-contact-form.update-button")
            : t("components.business-partner-contact-form.add-button")
          }
        />
      </Row>
    </>
  )
}