import { HttpClient, HttpStatusCode } from "@/domain/protocols/http";
import { UpdateVehicleComposition } from "@/domain/usecase";
import { RemoteUpdateVehicleCompositionDTO } from "../models";
import { AccessDeniedError, UnexpectedError } from "@/domain/errors";

export class RemoteUpdateVehicleComposition implements UpdateVehicleComposition {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<RemoteUpdateVehicleComposition.Model>
  ) {}

  async update(id: number, body: UpdateVehicleComposition.Params): Promise<void> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${String(id)}`,
      method: "put",
      body: body,
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.noContent:
        break;
      case HttpStatusCode.forbidden:
        throw new AccessDeniedError();
      default:
        throw new UnexpectedError();
    }
  }
}

export namespace RemoteUpdateVehicleComposition {
  export type Model = RemoteUpdateVehicleCompositionDTO;
}
