import { PREFIX_IDS } from '@/main/constants'

// ADD-BODYWORK
export const PREFIX_BODYWORK_ADDBODYWORK_BUTTON_OPEN  = `${PREFIX_IDS}prefix_bodywork_add_bodywork_button_open`
export const PREFIX_BODYWORK_ADDBODYWORK_BUTTON_CLOSE = `${PREFIX_IDS}prefix_bodywork_add_bodywork_button_close`
export const PREFIX_BODYWORK_ADDBODYWORK_BUTTON_SUBMIT = `${PREFIX_IDS}prefix_bodywork_add_bodywork_button_submit`
export const PREFIX_BODYWORK_ADDBODYWORK_INPUT_NAME = `${PREFIX_IDS}prefix_bodywork_add_bodywork_form_control_input_name`

//ADD-ORGANIZATION
export const PREFIX_ORGANIZATION_ADDORGANIZATION_BUTTON_OPEN = `${PREFIX_IDS}prefix_organization_add_organization_button_open`
export const PREFIX_ORGANIZATION_ADDORGANIZATION_BUTTON_CLOSE = `${PREFIX_IDS}prefix_organization_add_organization_button_close`
export const PREFIX_ORGANIZATION_ADDORGANIZATION_BUTTON_SUBMIT = `${PREFIX_IDS}prefix_organization_add_organization_button_submit`
export const PREFIX_ORGANIZATION_ADDORGANIZATION_INPUT_NAME = `${PREFIX_IDS}prefix_organization_add_organization_input_name`

//ADD-VEHICLE-TYPE
export const PREFIX_VEHICLETYPE_ADDVEHICLETYPE_BUTTON_OPEN = `${PREFIX_IDS}prefix_vehicle_type_add_vehicle_type_button_open`
export const PREFIX_VEHICLETYPE_ADDVEHICLETYPE_BUTTON_CLOSE = `${PREFIX_IDS}prefix_vehicle_type_add_vehicle_type_button_close`
export const PREFIX_VEHICLETYPE_ADDVEHICLETYPE_BUTTON_SUBMIT = `${PREFIX_IDS}prefix_vehicle_type_add_vehicle_type_button_submit`
export const PREFIX_VEHICLETYPE_ADDVEHICLETYPE_INPUT_NAME = `${PREFIX_IDS}prefix_vehicle_type_add_vehicle_type_input_name`

//ADD-BUSINESS-PARTNER
export const PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_BUTTON_OPEN = `${PREFIX_IDS}prefix_business_partner_add_business_partner_button_open`
export const PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_BUTTON_CLOSE = `${PREFIX_IDS}prefix_business_partner_add_business_partner_button_close`
export const PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_BUTTON_SUBMIT = `${PREFIX_IDS}prefix_business_partner_add_business_partner_button_submit`
export const PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_BUTTON_ADDADDRESS = `${PREFIX_IDS}prefix_business_partner_add_business_partner_add_address`
export const PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_INPUT_SIMPLENAME = `${PREFIX_IDS}prefix_business_partner_add_business_partner_input_simple_name`
export const PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_INPUT_CORPORATENAME = `${PREFIX_IDS}prefix_business_partner_add_business_partner_input_corporate_name`
export const PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_INPUT_DOCUMENT = `${PREFIX_IDS}prefix_business_partner_add_business_partner_select_document`
export const PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_INPUT_REFERENCE = `${PREFIX_IDS}prefix_business_partner_add_business_partner_input_reference`
export const PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNER_BUTTON_ADDCONTACT = `${PREFIX_IDS}prefix_business_partner_add_business_partner_add_contact`

//ADD-BUSINESS-PARTNER-ADDRESS
export const PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNERADDRESS_LABELCELL_ADDRESSTYPE = `${PREFIX_IDS}prefix_business_partner_add_business_partner_address_label_cell_address_type`
export const PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNERADDRESS_LABELCELL_NAME = `${PREFIX_IDS}prefix_business_partner_add_business_partner_address_label_cell_name`
export const PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNERADDRESS_INPUT_ZIPCODE = `${PREFIX_IDS}prefix_business_partner_add_business_partner_address_input_zip_code`
export const PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNERADDRESS_INPUT_ADDRESSNUMBER = `${PREFIX_IDS}prefix_business_partner_add_business_partner_address_input_address_number`
export const PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNERADDRESS_INPUT_ADDRESS = `${PREFIX_IDS}prefix_business_partner_add_business_partner_address_input_address`
export const PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNERADDRESS_INPUT_NEIGHBORHOOD = `${PREFIX_IDS}prefix_business_partner_add_business_partner_address_input_neighborhood`
export const PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNERADDRESS_INPUT_COMPLEMENT = `${PREFIX_IDS}prefix_business_partner_add_business_partner_address_input_complement`

//ADD-BUSINESS-PARTNER-CONTACT
export const PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNERCONTACT_LABELCELL_NAME = `${PREFIX_IDS}prefix_business_partner_add_business_partner_contact_label_cell_name`
export const PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNERCONTACT_LABELCELL_EMAIL = `${PREFIX_IDS}prefix_business_partner_add_business_partner_contact_label_cell_email`
export const PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNERCONTACT_LABELCELL_PHONE = `${PREFIX_IDS}prefix_business_partner_add_business_partner_contact_label_cell_phone`
export const PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNERCONTACT_INPUT_NAME = `${PREFIX_IDS}prefix_business_partner_add_business_partner_contact_input_name`
export const PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNERCONTACT_INPUT_PHONE = `${PREFIX_IDS}prefix_business_partner_add_business_partner_contact_input_phone`
export const PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNERCONTACT_INPUT_EMAIL = `${PREFIX_IDS}prefix_business_partner_add_business_partner_contact_input_email`
export const PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNERCONTACT_BUTTON_SUBMIT = `${PREFIX_IDS}prefix_business_partner_add_business_partner_contact_button_submit`
export const PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNERCONTACT_BUTTON_CLOSE = `${PREFIX_IDS}prefix_business_partner_add_business_partner_contact_button_close`

//UPDATE-BUSINESS-PARTNER
export const PREFIX_BUSINESSPARTNER_UPDATEBUSINESSPARTNER_BUTTON_CLOSE = `${PREFIX_IDS}prefix_business_partner_update_business_partner_button_close`
export const PREFIX_BUSINESSPARTNER_UPDATEBUSINESSPARTNER_BUTTON_SUBMIT = `${PREFIX_IDS}prefix_business_partner_update_business_partner_button_submit`
export const PREFIX_BUSINESSPARTNER_UPDATEBUSINESSPARTNER_BUTTON_ADDADDRESS = `${PREFIX_IDS}prefix_business_partner_update_business_partner_add_address`
export const PREFIX_BUSINESSPARTNER_UPDATEBUSINESSPARTNER_BUTTON_ADDCONTACT = `${PREFIX_IDS}prefix_business_partner_update_business_partner_add_contact`

//DISABLE-BUSINESS-PARTNER
export const PREFIX_BUSINESSPARTNER_DISABLEBUSINESSPARTNER_BUTTON_CLOSE = `${PREFIX_IDS}prefix_business_partner_disable_business_partner_button_close`
export const PREFIX_BUSINESSPARTNER_DISABLEBUSINESSPARTNER_BUTTON_SUBMIT = `${PREFIX_IDS}prefix_business_partner_disable_business_partner_button_submit`

// DISABLE-BODYWORK
export const PREFIX_BODYWORK_DISABLEBODYWORK_BUTTON_CLOSE = `${PREFIX_IDS}prefix_bodywork_disable_bodywork_button_close`
export const PREFIX_BODYWORK_DISABLEBODYWORK_BUTTON_SUBMIT = `${PREFIX_IDS}prefix_bodywork_disable_bodywork_button_submit`

// DISABLE-ORGANIZATION
export const PREFIX_ORGANIZATION_DISABLEORGANIZATION_BUTTON_CLOSE = `${PREFIX_IDS}prefix_organization_disable_organization_button_close`
export const PREFIX_ORGANIZATION_DISABLEORGANIZATION_BUTTON_SUBMIT = `${PREFIX_IDS}prefix_organization_disable_organization_button_submit`

// DISABLE-VEHICLE-TYPE
export const PREFIX_VEHICLETYPE_DISABLEVEHICLETYPE_BUTTON_CLOSE = `${PREFIX_IDS}prefix_vehicletype_disable_vehicle_type_button_close`
export const PREFIX_VEHICLETYPE_DISABLEVEHICLETYPE_BUTTON_SUBMIT = `${PREFIX_IDS}prefix_vehicletype_disable_vehicle_type_button_submit`

// UPDATE-BODYWORK
export const PREFIX_BODYWORK_UPDATEBODYWORK_BUTTON_CLOSE = `${PREFIX_IDS}prefix_bodywork_update_bodywork_button_close`
export const PREFIX_BODYWORK_UPDATEBODYWORK_INPUT_NAME = `${PREFIX_IDS}prefix_bodywork_update_bodywork_input_name`

//UPDATE-ORGANIZATION
export const PREFIX_ORGANIZATION_UPDATEORGANIZATION_BUTTON_CLOSE = `${PREFIX_IDS}prefix_organization_update_organization_button_close`
export const PREFIX_ORGANIZATION_UPDATEORGANIZATION_BUTTON_SUBMIT = `${PREFIX_IDS}prefix_organization_update_organization_button_submit`
export const PREFIX_ORGANIZATION_UPDATEORGANIZATION_INPUT_NAME = `${PREFIX_IDS}prefix_organization_update_organization_input_name`

//UPDATE-VEHICLE-TYPE
export const PREFIX_VEHICLETYPE_UPDATEVEHICLETYPE_BUTTON_CLOSE = `${PREFIX_IDS}prefix_vehicle_type_update_vehicle_type_button_close`
export const PREFIX_VEHICLETYPE_UPDATEVEHICLETYPE_BUTTON_SUBMIT = `${PREFIX_IDS}prefix_vehicle_type_update_vehicle_type_button_submit`
export const PREFIX_VEHICLETYPE_UPDATEVEHICLETYPE_INPUT_NAME = `${PREFIX_IDS}prefix_vehicle_type_update_vehicle_type_input_name`

//LIST-BODYWORK
export const PREFIX_BODYWORK_LISTBODYWORK_LABELCELL_NAME = `${PREFIX_IDS}prefix_bodywork_list_bodywork_label_cell_name`

//LIST-ORGANIZATION
export const PREFIX_ORGANIZATION_LISTORGANIZATION_LABELCELL_NAME = `${PREFIX_IDS}prefix_organization_list_organization_label_cell_name`

//LIST-VEHICLE-TYPE
export const PREFIX_VEHICLETYPE_LISTVEHICLETYPE_LABELCELL_NAME = `${PREFIX_IDS}prefix_vehicle_type_list_vehicle_type_label_cell_name`

//LIST-BUSINESS-PARTNER
export const PREFIX_BUSINESSPARTNER_LISTBUSINESSPARTNER_LABELCELL_SIMPLENAME = `${PREFIX_IDS}prefix_business_partner_list_business_partner_label_cell_simple_name`
export const PREFIX_BUSINESSPARTNER_LISTBUSINESSPARTNER_LABELCELL_DOCUMENT = `${PREFIX_IDS}prefix_business_partner_list_business_partner_label_cell_document`
