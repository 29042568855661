import React, { useState } from "react";
import {
  LoadStepPegeable,
  LoadStep,
} from "@/domain/usecase";
import {
  LabelCell,
  Pagination,
  Table,
  ProgressIndicator,
} from "fiorde-fe-components";
import { useQuery } from "@tanstack/react-query";
import {
  PageHeader,
} from "@/presentation/components";
import { t } from "i18next";

type StepListProps = {
  LoadStepPegeable: LoadStepPegeable;
  loadStep: LoadStep;
};

const columns = (
  loadStep: LoadStep,
) => [
  {
    label: t("page.step-list.columns.step-name"),
    key: "name",
    render: ({ name }: any) => <LabelCell>{name}</LabelCell>,
    size: 3.5,
  },
  {
    label: t("page.step-list.columns.menu"),
    key: "menu",
    render: ({ id, isActive }) => (
      <div style={{ display: "flex", gap: 8 }}>
      </div>
    ),
    size: 0.5,
  },
];

export function Step({
  LoadStepPegeable,
  loadStep,
}: StepListProps) {
  const [filter, setFilter] = useState({
    page: 0,
    size: 10,
    sort: "id,desc",
  });
  const { isLoading, isRefetching, data } = useQuery({
    queryKey: ["step-list", filter],
    queryFn: async () => LoadStepPegeable.load(filter),
  });
  return (
    <div>
      <ProgressIndicator
        visible={isLoading || isRefetching}
        content={t("components.progress-indicator.content")}
      />
      <PageHeader title={t("page.step-list.header")}>
      </PageHeader>
      <Table
        rows={data?.content || []}
        columns={columns(
          loadStep,
        )}
      />
      <Pagination
        count={data?.totalElements || 0}
        labelDisplay="exibindo"
        labelDisplayedRows="de"
        labelRowsPerPage="Etapas por página"
        onPageChange={(value) =>
          setFilter((filter: any) => ({ ...filter, page: value }))
        }
        onRowsPerPageChange={(value) =>
          setFilter((filter: any) => ({
            ...filter,
            size: value,
            page: 0,
          }))
        }
        tooltipBack="Anterior"
        tooltipFirst="Primeira"
        tooltipLast="Última"
        tooltipNext="Próxima"
        page={filter.page}
      />
    </div>
  );
}
