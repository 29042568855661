import { HttpClient, HttpStatusCode } from "@/domain/protocols/http";
import { UpdateSpot } from "@/domain/usecase";
import { RemoteUpdateSpotDTO } from "../models";
import { AccessDeniedError, UnexpectedError } from "@/domain/errors";

export class RemoteUpdateSpot implements UpdateSpot {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<RemoteUpdateSpot.Model>
  ) {}

  async update(id: number, body: UpdateSpot.Params): Promise<void> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${String(id)}`,
      method: "put",
      body: body,
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.noContent:
        break;
      case HttpStatusCode.forbidden:
        throw new AccessDeniedError();
      default:
        throw new UnexpectedError();
    }
  }
}

export namespace RemoteUpdateSpot {
  export type Model = RemoteUpdateSpotDTO;
}
