import {
  LabelCell,
  IconButton
} from "fiorde-fe-components"
import { EditIcon, TrashIcon } from "../icons"
import { t } from "i18next"
import { 
  PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNERCONTACT_LABELCELL_NAME,
  PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNERCONTACT_LABELCELL_EMAIL,
  PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNERCONTACT_LABELCELL_PHONE,
 } from "@/ids";

export function BusinessPartnerContactColumns({
  edit,
  remove
}){
  return [
    {
      label: t("components.business-partner-contact-columns.name"),
      key: "addressType",
      render: ({ name }: any) =>
        <LabelCell id={PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNERCONTACT_LABELCELL_NAME}>
          {name}
        </LabelCell>,
      size: 2,
    },
    {
      label: t("components.business-partner-contact-columns.email"),
      key: "name",
      render: ({ email }: any) => 
        <LabelCell id={PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNERCONTACT_LABELCELL_EMAIL}>
          {email}
        </LabelCell>,
      size: 4,
    },
    {
      label: t("components.business-partner-contact-columns.phone"),
      key: "phone",
      render: ({ phone }: any) => 
        <LabelCell id={PREFIX_BUSINESSPARTNER_ADDBUSINESSPARTNERCONTACT_LABELCELL_PHONE}>
          {phone}
        </LabelCell>,
      size: 3,
    },
    {
      label: t("components.business-partner-contact-columns.actions"),
      key: "actions",
      render: (contact, index) => (
        <div style={{ display: "flex", gap: 8 }}>
          <IconButton
            data-testid="open-button"
            onClick={() => edit(contact, index)}
            tooltip={t("components.business-partner-contact-columns.edit-tooltip")}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            data-testid="open-button"
            onClick={() => remove(index)}
            tooltip={t("components.business-partner-contact-columns.remove-tooltip")}
          >
            <TrashIcon />
          </IconButton>
        </div>
      ),
      size: 1,
    },
  ]
}