import React, { FC, ReactNode, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ChildrenContainer, ExtensionMenuContainer, Container } from './route-wrapper-style'
import { t } from 'i18next';
import { ExtensionMenu } from 'fiorde-fe-components';

export function RouteWrapper ({ makeComponent }): JSX.Element {
  const [open, setOpen] = useState(false)
  const navigate = useNavigate()


  const handleChange = (isOpened): void => {
    setOpen(isOpened)
  }

  const menuItems: any = [
    {
      icon: 'register',
      label: t('sidebar.registrations.title'),
      onclick: function noRefCheck () {},
      subMenuItems: [
        {
          label: t('sidebar.registrations.material-family'),
          onClick: () => navigate('/material-family/list')
        },
        {
          label: t('sidebar.registrations.spot'),
          onClick: () => navigate('/spot/list'),
        },
        {
          label: t('sidebar.registrations.dock'),
          onClick: () => navigate('/dock/list'),
        },
        {
          label: t('sidebar.registrations.vehicle-composition'),
          onClick: () => navigate('/vehicle-composition/list'),
        },
        {
          label: t('sidebar.registrations.check-list'),
          onClick: () => navigate('/check-list/list'),
        },
        {
          label: t('sidebar.registrations.step'),
          onClick: () => navigate('/step/list'),
        },
        {
          label: t('sidebar.registrations.access'),
          onClick: () => navigate('/access/list'),
        }
      ]
    },
  ]
  return (
    <Container>
      <ExtensionMenuContainer>
        <ExtensionMenu
          menuItems={menuItems}
          module=""
          onChange={handleChange}
          selectedSubItem={undefined}
          selectedSubMenu={undefined}
        />
      </ExtensionMenuContainer>
      <ChildrenContainer isOpen={open}>{makeComponent()}</ChildrenContainer>
    </Container>
  )
}
