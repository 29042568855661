import React from "react";
import { Step } from "@/presentation/pages/step/page";
import {
  makeRemoteLoadStepPegeable,
  makeRemoteLoadStep,
} from "@/main/factories/usecases";

export const makeStepList: React.FC = (props) => {
  return (
    <Step
      {...props}
      LoadStepPegeable={makeRemoteLoadStepPegeable()}
      loadStep={makeRemoteLoadStep()}
    />
  );
};
