import { AccessDeniedError, UnexpectedError } from "@/domain/errors";
import { HttpClient, HttpStatusCode } from "@/domain/protocols/http";
import { LoadVehicleTypeList } from "@/domain/usecase";
import { RemoteLoadVehicleTypeListDTO } from "../models";

export class RemoteLoadVehicleTypeList implements LoadVehicleTypeList {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<LoadVehicleTypeList.Model>
  ) {}

  async load(): Promise<LoadVehicleTypeList.Model> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: "get",
    });
    const LoadVehicleTypeList = httpResponse.body;
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return LoadVehicleTypeList;
      case HttpStatusCode.forbidden:
        throw new AccessDeniedError();
      default:
        throw new UnexpectedError();
    }
  }
}

export namespace RemoteLoadVehicleTypeList {
  export type Model = RemoteLoadVehicleTypeListDTO;
}
