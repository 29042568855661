var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect, useContext } from 'react';
import { Modal, Box } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CloseIcon from '../../../application/icons/CloseIcon';
import { I18n } from 'react-redux-i18n';
import { ButtonDiv, CloseIconContainer, Form, HeaderDiv, Label, ModalContainer, PlaceholderDiv, PlaceholderSpan, RedColorSpan, RowDiv, RowReverseDiv, Title } from '../StyledComponents/modalStyles';
import { Input } from '../CostModal/CostModalStyles';
import { Button } from 'fiorde-fe-components';
import ControlledToolTip from '../ControlledToolTip/ControlledToolTip';
import { Container } from './FareModalStyles';
import API from '../../../infrastructure/api';
import { NumberInput, StyledPaper } from '../../pages/NewProposal/steps/StepsStyles';
import FormatNumber from '../../../application/utils/formatNumber';
import { ProposalContext } from '../../../presentation/pages/NewProposal/context/ProposalContext';
import { ModalTypes, SpecificationsType } from '../../../application/enum/enum';
import { FareItemsTypes } from '../../../application/enum/costEnum';
import { useCalculationTypes } from '../../hooks/index';
import ControlledInput from '../ControlledInput';
export const initialState = {
    type: '',
    expense: null,
    agent: {
        agent: '',
        id: null,
        idBusinessPartnerAgent: null,
        shippingCompany: '',
        idBusinessPartnerTransportCompany: null
    },
    selectedAgent: '',
    saleValue: '',
    minimumValue: '',
    saleCurrency: 'BRL',
    selectedContainer: null,
    id: null,
    idCalculationType: null
};
const FareModal = ({ dataProp, action, open, setClose, title, modal, specifications, containerItems, currency, AllAgents }) => {
    var _a, _b;
    const [data, setData] = useState(initialState);
    const [invalidInput, setInvalidInput] = useState(false);
    const [typeList, setTypeList] = useState([]);
    const [serviceList, setServiceList] = useState([]);
    const [currencyList, setCurrencyList] = useState([]);
    const [agentList, setAgentList] = useState([]);
    const { proposal } = useContext(ProposalContext);
    const { data: calculationTypes = [] } = useCalculationTypes();
    const verifyContainerItems = () => {
        if (containerItems.length === 1) {
            setData(Object.assign(Object.assign({}, data), { selectedContainer: containerItems[0].type }));
        }
        else {
            setData(Object.assign(Object.assign({}, data), { selectedContainer: '' }));
        }
    };
    useEffect(() => {
        verifyContainerItems();
    }, [containerItems]);
    const rgxFloat = /^[0-9]*,?[0-9]*$/;
    const validateFloatInput = (value) => {
        return value.match(rgxFloat);
    };
    const isValid = () => {
        var _a;
        return !(data.idCalculationType === null ||
            data.saleValue.length === 0 ||
            data.saleCurrency.length === 0 ||
            (data.selectedContainer === '' || data.selectedContainer === null) ||
            (data.selectedAgent === '' || data.selectedAgent === null) ||
            (data.expense === null || ((_a = data.expense) === null || _a === void 0 ? void 0 : _a.length) === 0));
    };
    const saleValueHandler = (e) => {
        const validatedInput = validateFloatInput(e.target.value);
        if (validatedInput !== null) {
            setData(Object.assign(Object.assign({}, data), { saleValue: validatedInput[0] }));
        }
    };
    const minimumValueHandler = (e) => {
        const validatedInput = validateFloatInput(e.target.value);
        if (validatedInput !== null) {
            setData(Object.assign(Object.assign({}, data), { minimumValue: validatedInput[0] }));
        }
    };
    const handleOnClose = () => {
        setData(initialState);
        setInvalidInput(false);
        setClose();
    };
    const handleAction = () => {
        if (isValid()) {
            const newData = data;
            newData.agent = agentList.find(a => a.agent === newData.selectedAgent);
            action(newData);
            handleOnClose();
        }
        else {
            setInvalidInput(true);
        }
    };
    const getAgents = () => {
        const proposalAgentsidBusinessPartnerAgent = proposal.agents.map(a => a.idBusinessPartnerAgent);
        const getSomeAgents = AllAgents === null || AllAgents === void 0 ? void 0 : AllAgents.map(a => {
            var _a, _b, _c, _d;
            return proposalAgentsidBusinessPartnerAgent.includes((_a = a === null || a === void 0 ? void 0 : a.businessPartner) === null || _a === void 0 ? void 0 : _a.id)
                ? ({
                    idBusinessPartnerAgent: (_b = a === null || a === void 0 ? void 0 : a.businessPartner) === null || _b === void 0 ? void 0 : _b.id,
                    agent: (_c = a === null || a === void 0 ? void 0 : a.businessPartner) === null || _c === void 0 ? void 0 : _c.simpleName,
                    idBusinessPartnerTransportCompany: (_d = proposal.agents.find(find => { var _a; return find.idBusinessPartnerAgent === ((_a = a === null || a === void 0 ? void 0 : a.businessPartner) === null || _a === void 0 ? void 0 : _a.id); })) === null || _d === void 0 ? void 0 : _d.idBusinessPartnerTransportCompany
                })
                : null;
        });
        return getSomeAgents === null || getSomeAgents === void 0 ? void 0 : getSomeAgents.filter(f => f != null);
    };
    useEffect(() => {
        var _a, _b, _c;
        if (dataProp !== initialState) {
            setData(Object.assign(Object.assign({}, dataProp), { selectedAgent: (_b = (_a = AllAgents.find((agent) => agent.businessPartner.id === dataProp.agent.idBusinessPartnerAgent)) === null || _a === void 0 ? void 0 : _a.businessPartner) === null || _b === void 0 ? void 0 : _b.simpleName, selectedContainer: containerItems.length > 0 ? containerItems[0].type : '' }));
        }
        else if (proposal.agents.length === 1 && AllAgents !== undefined && currency.length !== 0) {
            setData(Object.assign(Object.assign({}, data), { selectedAgent: (_c = getAgents()[0]) === null || _c === void 0 ? void 0 : _c.agent, saleCurrency: currency }));
        }
    }, [open]);
    useEffect(() => {
        void (function () {
            return __awaiter(this, void 0, void 0, function* () {
                yield API.getCurrencies()
                    .then((response) => setCurrencyList(response))
                    .catch((err) => console.log(err));
            });
        })();
    }, []);
    useEffect(() => {
        void (function () {
            return __awaiter(this, void 0, void 0, function* () {
                yield API.getService()
                    .then((response) => setServiceList(response))
                    .catch((err) => console.log(err));
            });
        })();
    }, []);
    useEffect(() => {
        if (AllAgents !== undefined) {
            setAgentList(getAgents());
        }
    }, [proposal, AllAgents]);
    useEffect(() => {
        let newTypeList = [];
        if ((calculationTypes === null || calculationTypes === void 0 ? void 0 : calculationTypes.length) > 0) {
            switch (true) {
                case modal === ModalTypes.Sea && specifications === SpecificationsType.Fcl:
                    newTypeList = calculationTypes.filter(item => item.txCalculationType === FareItemsTypes.Container)
                        .map(item => ({ name: item.txCalculationType, value: item.idCalculationType }));
                    break;
                case (modal === ModalTypes.Sea && specifications === SpecificationsType.Lcl) ||
                    (modal === ModalTypes.Sea && specifications === SpecificationsType.BreakBulk) ||
                    (modal === ModalTypes.Sea && specifications === SpecificationsType.Roro):
                    newTypeList = calculationTypes.filter(item => item.txCalculationType === FareItemsTypes.Ton || item.txCalculationType === FareItemsTypes.Fixed)
                        .map(item => ({ name: item.txCalculationType, value: item.idCalculationType }));
                    break;
                case modal === ModalTypes.Air:
                    newTypeList = calculationTypes
                        .filter(item => item.txCalculationType === FareItemsTypes.Kilo || item.txCalculationType === FareItemsTypes.Fixed || item.txCalculationType === FareItemsTypes.Cw)
                        .map(item => ({ name: item.txCalculationType, value: item.idCalculationType }));
                    break;
                case modal === ModalTypes.Land:
                    newTypeList = calculationTypes
                        .filter(item => item.txCalculationType === FareItemsTypes.Fixed)
                        .map(item => ({ name: item.txCalculationType, value: item.idCalculationType }));
                    break;
                default:
                    newTypeList = [];
            }
            setTypeList(newTypeList);
        }
    }, [modal, specifications, calculationTypes]);
    return (React.createElement(Modal, { open: open, onClose: handleOnClose },
        React.createElement(ModalContainer, null,
            React.createElement(HeaderDiv, null,
                React.createElement(Title, null, title),
                React.createElement(RowReverseDiv, null,
                    React.createElement(CloseIconContainer, null,
                        React.createElement(CloseIcon, { onClick: handleOnClose })))),
            React.createElement(Form, null,
                React.createElement(RowDiv, null,
                    React.createElement(Label, { width: "350px", color: invalidInput && (data.expense === null || ((_a = data.expense) === null || _a === void 0 ? void 0 : _a.length) === 0) ? 'red' : '' },
                        I18n.t('components.fareModal.expense'),
                        React.createElement(RedColorSpan, null, " *")),
                    React.createElement(Label, { width: "113px", paddingLeft: "10px", color: invalidInput && (data.idCalculationType === null || data.idCalculationType === undefined) ? 'red' : '' },
                        I18n.t('components.fareModal.type'),
                        React.createElement(RedColorSpan, null, " *"))),
                React.createElement(RowDiv, { margin: true },
                    React.createElement(Container, { width: "350px", height: "32px" },
                        React.createElement(Autocomplete, { onChange: (e, newValue) => setData(Object.assign(Object.assign({}, data), { expense: newValue })), options: serviceList.map((option) => option.service), value: data.expense, renderInput: (params) => {
                                var _a;
                                return (React.createElement("div", { ref: params.InputProps.ref },
                                    React.createElement(ControlledInput, Object.assign({}, params.inputProps, { toolTipTitle: I18n.t('components.itemModal.requiredField'), invalid: invalidInput && (data.expense === null || ((_a = data.expense) === null || _a === void 0 ? void 0 : _a.length) === 0), filled: data.expense, placeholder: I18n.t('components.fareModal.choose'), style: { width: '350px' }, variant: "outlined", size: "small" })),
                                    React.createElement(Box, Object.assign({}, params.inputProps, { className: "dropdownCustom" }),
                                        React.createElement(ArrowDropDownIcon, null))));
                            }, PaperComponent: (params) => React.createElement(StyledPaper, Object.assign({}, params)) })),
                    React.createElement(Container, { width: "150px", height: "32px", margin: "0 0 5px 10px", style: { marginTop: '-45px' } },
                        React.createElement(Autocomplete, { onChange: (_e, newValue) => { var _a; return setData(Object.assign(Object.assign({}, data), { idCalculationType: (_a = typeList.find((type) => type.name === newValue)) === null || _a === void 0 ? void 0 : _a.value })); }, options: typeList.map((option) => option.name), value: (_b = typeList.find((type) => type.value === data.idCalculationType)) === null || _b === void 0 ? void 0 : _b.name, renderInput: (params) => {
                                var _a;
                                return (React.createElement("div", { ref: params.InputProps.ref, style: { display: 'grid' } },
                                    React.createElement(ControlledInput, Object.assign({}, params.inputProps, { toolTipTitle: I18n.t('components.itemModal.requiredField'), invalid: invalidInput && (data.idCalculationType === null || data.idCalculationType === undefined), filled: (_a = typeList.find((type) => type.value === data.idCalculationType)) === null || _a === void 0 ? void 0 : _a.name, placeholder: I18n.t('components.fareModal.choose'), style: { width: '150px', gridColumn: 1, gridRow: 1 }, variant: "outlined", size: "small" })),
                                    React.createElement(Box, Object.assign({}, params.inputProps, { style: { gridColumn: 1, gridRow: 1, marginTop: '16px', marginLeft: '125px' } }),
                                        React.createElement(ArrowDropDownIcon, null))));
                            }, PaperComponent: (params) => React.createElement(StyledPaper, Object.assign({}, params)) }))),
                (agentList === null || agentList === void 0 ? void 0 : agentList.length) > 0 && (React.createElement(React.Fragment, null,
                    " ",
                    React.createElement(RowDiv, null,
                        React.createElement(Label, { width: "513px", style: { marginTop: '30px' }, color: invalidInput && (data.selectedAgent === '' || data.selectedAgent === null) ? 'red' : '' },
                            I18n.t('components.fareModal.agent'),
                            React.createElement(RedColorSpan, null, " *"))),
                    React.createElement(RowDiv, { margin: true },
                        React.createElement(Container, { width: "513px", height: "32px" },
                            React.createElement(Autocomplete, { onChange: (e, newValue) => setData(Object.assign(Object.assign({}, data), { selectedAgent: newValue })), options: agentList.map(agent => agent.agent), value: data.selectedAgent, renderInput: (params) => (React.createElement("div", { ref: params.InputProps.ref },
                                    React.createElement(ControlledInput, Object.assign({}, params.inputProps, { filled: data.selectedAgent, toolTipTitle: I18n.t('components.itemModal.requiredField'), invalid: invalidInput && (data.selectedAgent === '' || data.selectedAgent === null), placeholder: I18n.t('components.fareModal.choose'), style: { width: '513px' }, variant: "outlined", size: "small" })),
                                    React.createElement(Box, Object.assign({}, params.inputProps, { className: "dropdownCustom" }),
                                        React.createElement(ArrowDropDownIcon, null)))), PaperComponent: (params) => React.createElement(StyledPaper, Object.assign({}, params)) }))))),
                specifications === 'fcl' && (React.createElement(React.Fragment, null,
                    React.createElement(RowDiv, null,
                        React.createElement(Label, { width: "100%", style: { marginTop: '30px' }, color: invalidInput && (data.selectedContainer === '' || data.selectedContainer === null) ? 'red' : '' },
                            I18n.t('components.costModal.container'),
                            React.createElement(RedColorSpan, null, " *"))),
                    React.createElement(RowDiv, { style: { position: 'relative' }, margin: true },
                        React.createElement(Autocomplete, { options: containerItems.map((item) => item.type), value: data.selectedContainer, style: { maxHeight: '20px', marginBottom: '20px' }, onChange: (e, newValue) => setData(Object.assign(Object.assign({}, data), { selectedContainer: newValue })), renderInput: (params) => (React.createElement("div", { ref: params.InputProps.ref },
                                React.createElement(ControlledInput, Object.assign({}, params.inputProps, { filled: data.selectedContainer, placeholder: I18n.t('components.costModal.choose'), toolTipTitle: I18n.t('components.itemModal.requiredField'), invalid: invalidInput && (data.selectedContainer === '' || data.selectedContainer === null), style: { width: '513px' }, variant: "outlined", size: "small" })),
                                React.createElement(Box, Object.assign({}, params.inputProps, { className: "dropdownContainer" }),
                                    React.createElement(ArrowDropDownIcon, null)))), PaperComponent: (params) => React.createElement(StyledPaper, Object.assign({}, params)) })))),
                React.createElement(RowDiv, null,
                    React.createElement(Label, { width: "263px", color: invalidInput && data.saleValue.length === 0 ? 'red' : '', style: { marginTop: '30px' } },
                        I18n.t('components.fareModal.saleValue'),
                        React.createElement(RedColorSpan, null, " *")),
                    React.createElement(Label, null, I18n.t('components.fareModal.minimum'))),
                React.createElement(RowDiv, null,
                    React.createElement(Container, { style: { position: 'relative', marginRight: '14px' } },
                        React.createElement(Autocomplete, { value: currency, options: currencyList.map((option) => option.id), disabled: true, renderInput: (params) => (React.createElement("div", { ref: params.InputProps.ref },
                                React.createElement(Input, Object.assign({}, params.inputProps, { width: "84px", placeholder: data.saleCurrency, toolTipTitle: I18n.t('components.itemModal.requiredField'), invalid: invalidInput && data.saleCurrency.length === 0, disabled: true })),
                                React.createElement(Box, { className: "dropdown" },
                                    React.createElement(ArrowDropDownIcon, null)))), PaperComponent: (params) => React.createElement(StyledPaper, Object.assign({}, params)) })),
                    React.createElement(ControlledToolTip, { title: I18n.t('components.itemModal.requiredField'), open: invalidInput && data.saleValue.length === 0 },
                        React.createElement(PlaceholderDiv, null,
                            React.createElement("label", null,
                                data.saleValue.length === 0 && (React.createElement(PlaceholderSpan, null, I18n.t('components.fareModal.value'))),
                                React.createElement(NumberInput, { decimalSeparator: ',', thousandSeparator: '.', decimalScale: 2, customInput: Input, format: (value) => FormatNumber.rightToLeftFormatter(value, 2), onChange: saleValueHandler, value: data.saleValue, filled: data.saleValue, invalid: invalidInput && data.saleValue.length === 0 })))),
                    React.createElement(PlaceholderDiv, null,
                        React.createElement("label", null,
                            data.minimumValue.length === 0 && (React.createElement(PlaceholderSpan, null, I18n.t('components.fareModal.value'))),
                            React.createElement(NumberInput, { decimalSeparator: ',', thousandSeparator: '.', decimalScale: 2, customInput: Input, format: (value) => FormatNumber.rightToLeftFormatter(value, 2), onChange: minimumValueHandler, value: data.minimumValue, filled: data.minimumValue })))),
                React.createElement(RowDiv, null,
                    React.createElement(ButtonDiv, null,
                        React.createElement(Button, { disabled: false, text: I18n.t('components.itemModal.save'), backgroundGreen: true, icon: "", onAction: handleAction, tooltip: I18n.t('components.itemModal.save') })))))));
};
export default FareModal;
