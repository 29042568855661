import { HttpClient, HttpStatusCode } from "@/domain/protocols/http";
import { RemoteAddSpotDTO } from "../models";
import { AccessDeniedError, UnexpectedError } from "@/domain/errors";
import { AddSpot } from "@/domain/usecase";

export class RemoteAddSpot implements AddSpot {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<RemoteAddSpot.Model>
  ) {}

  async add(params: AddSpot.Params): Promise<void> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: "post",
      body: params,
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.created:
        break;
      case HttpStatusCode.forbidden:
        throw new AccessDeniedError();
      default:
        throw new UnexpectedError();
    }
  }
}

export namespace RemoteAddSpot {
  export type Model = RemoteAddSpotDTO;
}
