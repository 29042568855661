import { AccessDeniedError, UnexpectedError } from "@/domain/errors";
import { HttpClient, HttpStatusCode } from "@/domain/protocols/http";
import { LoadDockPegeable } from "@/domain/usecase";
import { RemoteLoadDockPegeableDTO } from "@/data/models";

export class RemoteLoadDockPegeable implements LoadDockPegeable {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<RemoteLoadDockPegeable.Model>
  ) {}

  async load(filters: Object): Promise<LoadDockPegeable.Model> {
    const queryString = Object.entries(filters)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join("&");
    const httpResponse = await this.httpClient.request({
      url: `${this.url}?${queryString}`,
      method: "get",
    });
    const loadDockPegeable = httpResponse.body;
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return loadDockPegeable;
      case HttpStatusCode.forbidden:
        throw new AccessDeniedError();
      default:
        throw new UnexpectedError();
    }
  }
}

export namespace RemoteLoadDockPegeable {
  export type Model = RemoteLoadDockPegeableDTO;
}
