import { HttpClient, HttpStatusCode } from "@/domain/protocols/http";
import { RemoteAddDockDTO } from "../models";
import { AccessDeniedError, UnexpectedError } from "@/domain/errors";
import { AddDock } from "@/domain/usecase";

export class RemoteAddDock implements AddDock {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<RemoteAddDock.Model>
  ) {}

  async add(params: AddDock.Params): Promise<void> {
    const httpResponse = await this.httpClient.request({
      url: this.url,
      method: "post",
      body: params,
    });
    switch (httpResponse.statusCode) {
      case HttpStatusCode.created:
        break;
      case HttpStatusCode.forbidden:
        throw new AccessDeniedError();
      default:
        throw new UnexpectedError();
    }
  }
}

export namespace RemoteAddDock {
  export type Model = RemoteAddDockDTO;
}
