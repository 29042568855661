import React from 'react'
import { HashRouter, Routes, Route } from 'react-router-dom'
import { RouteWrapper } from '../route-wrapper/route-wrapper'
import {
  makeMaterialFamilyList,
  makeSpotList,
  makeDockList,
  makeHome,
  makeVehicleCompositionList,
  makeStepList
} from '@/main/factories/pages'

export function Router() {
  return (
    <HashRouter basename='/yard'>
      <Routes>
        <Route path="/" element={
          <RouteWrapper makeComponent={makeHome}/>
        } />
        <Route path="/material-family/list" element={
          <RouteWrapper makeComponent={makeMaterialFamilyList}/>
        } />
        <Route path="/spot/list" element={
          <RouteWrapper makeComponent={makeSpotList}/>
        } />
        <Route path="/dock/list" element={
          <RouteWrapper makeComponent={makeDockList}/>
        } />
        <Route path="/vehicle-composition/list" element={
          <RouteWrapper makeComponent={makeVehicleCompositionList}/>
        } />
        <Route path="/check-list/list" element={
          <RouteWrapper makeComponent={makeMaterialFamilyList}/>
        } />
        <Route path="/check-list/detail" element={
          <RouteWrapper makeComponent={makeMaterialFamilyList}/>
        } />
        <Route path="/check-list/detail/:id" element={
          <RouteWrapper makeComponent={makeMaterialFamilyList}/>
        } />
        <Route path="/step/list" element={
          <RouteWrapper makeComponent={makeStepList}/>
        } />
        <Route path="/access/list" element={
          <RouteWrapper makeComponent={makeMaterialFamilyList}/>
        } />
        <Route path="/access/detail" element={
          <RouteWrapper makeComponent={makeMaterialFamilyList}/>
        } />
        <Route path="/access/detail/:id" element={
          <RouteWrapper makeComponent={makeMaterialFamilyList}/>
        } />
      </Routes>
    </HashRouter>
  );
}
