import React, { useState } from "react";
import { 
  AddDock as DomainAddDock,
  LoadDockStatusList,
  LoadVehicleCompositionList,
  LoadMaterialFamilyList,
  LoadMovementTypeList
} from "@/domain/usecase";
import {
  Button,
  Modal,
  FormControl,
  Input,
  Messages,
  ProgressIndicator,
  Select,
  Radio
} from "fiorde-fe-components";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { t } from "i18next";
import { makeSelectValues } from "@/presentation/utils";
import {
  PREFIX_REGISTER_ADDDOCK_BUTTON_OPEN,
  PREFIX_REGISTER_ADDDOCK_MODAL,
  PREFIX_REGISTER_ADDDOCK_BUTTON_CLOSE,
  PREFIX_REGISTER_ADDDOCK_BUTTON_SUBMIT,
  PREFIX_REGISTER_ADDDOCK_FORMCONTROL_INPUTNAME,
  PREFIX_REGISTER_ADDDOCK_INPUT_NAME,
  PREFIX_REGISTER_ADDDOCK_FORMCONTROL_INPUTDESCRIPTION,
  PREFIX_REGISTER_ADDDOCK_INPUT_DESCRIPTION,
  PREFIX_REGISTER_ADDDOCK_FORMCONTROL_SELECTMATERIALFAMILY,
  PREFIX_REGISTER_ADDDOCK_FORMCONTROL_SELECTVEHICLECOMPOSITION,
  PREFIX_REGISTER_ADDDOCK_FORMCONTROL_SELECTMOVEMENTTYPE,
  PREFIX_REGISTER_ADDDOCK_FORMCONTROL_SELECTDOCKSTATUS,
} from "@/ids";

type AddDockProps = {
  addDock: DomainAddDock;
  loadDockStatusList: LoadDockStatusList;
  loadMaterialFamilyList: LoadMaterialFamilyList;
  loadVehicleCompositionList: LoadVehicleCompositionList;
  loadMovementTypeList: LoadMovementTypeList
};

export function AddDock({ 
  addDock,
  loadDockStatusList,
  loadVehicleCompositionList,
  loadMaterialFamilyList,
  loadMovementTypeList
}: AddDockProps) {
  const queryClient = useQueryClient();
  const [isLoading, setLoading] = useState(false);
  const [name, setname] = useState("");
  const [description, setDescription] = useState("");
  const [dockStatus, setDockStatus] = useState(null);
  const [vehicleCompositions, setVehicleCompositions] = useState([]);
  const [materialFamilies, setMaterialFamilies] = useState([]);
  const [open, setOpen] = useState(false);
  const [movementTypes, setMovementTypes] = useState([]);

  const [responseMessage, setResponseMessage] = useState({
    message: "",
    severity: "",
  });

  const { data: dockStatusList } = useQuery<any>({
    queryKey: ["dock-status"],
    queryFn: async () => await loadDockStatusList.load(),
    enabled: open,
  });

  const { data: vehicleCompositionList } = useQuery<any>({
    queryKey: ["vehicle-composition-all"],
    queryFn: async () => await loadVehicleCompositionList.load(),
    enabled: open,
  });

  const { data: materialFamilyList } = useQuery<any>({
    queryKey: ["material-family-all"],
    queryFn: async () => await loadMaterialFamilyList.load(),
    enabled: open,
  });

  const { data: movementTypeList } = useQuery({
    queryKey: ["movement-type-all"],
    queryFn: async () => loadMovementTypeList.load(),
  });

  function handleSubmit(): void {
    setLoading(true);
    addDock
      .add({
        name,
        description,
        dockStatus: {
          id: dockStatus,
        },
        vehicleCompositions: vehicleCompositions?.map(vehicleCompositionId => ({ id: vehicleCompositionId })),
        materialFamilies: materialFamilies?.map(materialFamily => ({ id: materialFamily })),
        movementTypes: movementTypes?.map(movementType => ({ id: movementType })),
        isActive: true,
      })
      .then(() => {
        queryClient.invalidateQueries({ queryKey: ["dock-list"] });
        setResponseMessage({
          message: t("components.add-dock.response-success-message"),
          severity: "success",
        });
        handleClose();
      })
      .catch((err) => {
        setResponseMessage({
          message: err?.message,
          severity: "error",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleOpen() {
    setOpen(true);
  }

  function handleClose() {
    setname("");
    setDescription("");
    setDockStatus(null);
    setOpen(false);
    setVehicleCompositions([]);
    setMaterialFamilies([]);
    setMovementTypes([]);
  }

  return (
    <>
      <ProgressIndicator
        visible={isLoading}
        content={t("components.progress-indicator.content")}
      />
      <Button
        id={PREFIX_REGISTER_ADDDOCK_BUTTON_OPEN}
        icon="add"
        data-testid="open-button"
        onAction={() => handleOpen()}
        text={t("components.add-dock.open-button")}
      />
      <Modal
        id={PREFIX_REGISTER_ADDDOCK_MODAL}
        width={600}
        title={t("components.add-dock.open-button")}
        isOpen={open}
        onClose={handleClose}
        actions={[
          <Button
            id={PREFIX_REGISTER_ADDDOCK_BUTTON_CLOSE}
            data-testid="close-button"
            backgroundGreen={false}
            onAction={() => handleClose()}
            text={t("components.add-dock.close-button")}
          />,
          <Button
            id={PREFIX_REGISTER_ADDDOCK_BUTTON_SUBMIT}
            data-testid="submit-button"
            onAction={() => handleSubmit()}
            text={t("components.add-dock.submit-button")}
          />,
        ]}
      >
        <FormControl 
          id={PREFIX_REGISTER_ADDDOCK_FORMCONTROL_INPUTNAME} 
          label={t("components.add-dock.input-label-name")}>
          <Input
            inputProps={{ "data-testid": "name" }}
            id={PREFIX_REGISTER_ADDDOCK_INPUT_NAME}
            onChange={(event: any) => setname(event.target.value)}
            value={name}
            placeholder={t("components.add-dock.input-placeholder-name")}
          />
        </FormControl>
        <FormControl 
          id={PREFIX_REGISTER_ADDDOCK_FORMCONTROL_INPUTDESCRIPTION}
          label={t("components.add-dock.input-label-description")}>
          <Input
            inputProps={{ "data-testid": "description" }}
            id={PREFIX_REGISTER_ADDDOCK_INPUT_DESCRIPTION}
            onChange={(event: any) => setDescription(event.target.value)}
            value={description}
            placeholder={t("components.add-dock.input-placeholder-description")}
            multiline={true}
            rows={2}
          />
        </FormControl>
        <FormControl 
          id={PREFIX_REGISTER_ADDDOCK_FORMCONTROL_SELECTMATERIALFAMILY}
          label={t("components.add-dock.material-family-label")}>
          <Select
            name="materialFamily"
            options={makeSelectValues({
              label: "name",
              value: "id",
              array: materialFamilyList,
            })}
            onChange={(value) => setMaterialFamilies(value)}
            value={materialFamilies}
            multiple
            placeholder={t("components.add-dock.material-family-placeholder")}
          />
        </FormControl>
        <FormControl 
          id={PREFIX_REGISTER_ADDDOCK_FORMCONTROL_SELECTVEHICLECOMPOSITION}
          label={t("components.add-dock.vehicle-composition-label")}>
          <Select
            name="vehicleComposition"
            options={makeSelectValues({
              label: "name",
              value: "id",
              array: vehicleCompositionList,
            })}
            onChange={(value) => setVehicleCompositions(value)}
            value={vehicleCompositions}
            multiple
            placeholder={t("components.add-dock.vehicle-composition-placeholder")}
          />
        </FormControl>
        <FormControl 
          id={PREFIX_REGISTER_ADDDOCK_FORMCONTROL_SELECTMOVEMENTTYPE}
          label={t("components.add-dock.movement-type-label")}>
          <Select
            name="movementType"
            options={makeSelectValues({
              label: "name",
              value: "id",
              array: movementTypeList,
            })}
            onChange={(value) => setMovementTypes(value)}
            value={movementTypes}
            multiple
            placeholder={t("components.add-dock.movement-type-placeholder")}
          />
        </FormControl>
        <FormControl 
          id={PREFIX_REGISTER_ADDDOCK_FORMCONTROL_SELECTDOCKSTATUS}
          label={t("components.add-dock.dock-status-label")}>
          <Select
            name="dockStatus"
            options={makeSelectValues({
              label: "name",
              value: "id",
              array: dockStatusList,
            })}
            onChange={(value) => setDockStatus(value)}
            value={dockStatus}
            placeholder={t("components.add-dock.dock-status-placeholder")}
          />
        </FormControl>
      </Modal>
      {responseMessage.message && (
        <Messages
          message={responseMessage.message}
          severity={responseMessage.severity}
          closable
          closeAlert={() =>
            setResponseMessage({
              message: "",
              severity: "",
            })
          }
        />
      )}
    </>
  );
}
