import { AccessDeniedError, UnexpectedError } from "@/domain/errors";
import { HttpClient, HttpStatusCode } from "@/domain/protocols/http";
import { LoadDock } from "@/domain/usecase";
import { RemoteLoadDockDTO } from "@/data/models";

export class RemoteLoadDock implements LoadDock {
  constructor(
    private readonly url: string,
    private readonly httpClient: HttpClient<LoadDock.Model>
  ) {}

  async load(id: number): Promise<LoadDock.Model> {
    const httpResponse = await this.httpClient.request({
      url: `${this.url}/${id}`,
      method: "get",
    });
    const LoadDock = httpResponse.body;
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok:
        return LoadDock;
      case HttpStatusCode.forbidden:
        throw new AccessDeniedError();
      default:
        throw new UnexpectedError();
    }
  }
}

export namespace RemoteLoadDock {
  export type Model = RemoteLoadDockDTO;
}
