import React, { createContext, useState, useMemo } from 'react';
export const emptyProposalValue = {
    idProposal: null,
    idProposalImportFreight: null,
    referenceProposal: 'Ref-000000',
    idBusinessPartnerCustomer: 0,
    operationType: 'IMPORT FREIGHT',
    idProposalStatus: 1,
    codeUserCreation: 0,
    validityDate: '2022-05-12T13:03:46.340Z',
    referenceClientProposal: '',
    generalObservations: '',
    internalObservations: '',
    requester: '',
    cargoCollectionAddress: '',
    cargoDeliveryAddress: '',
    costs: [],
    totalCosts: [],
    idProposalType: 0,
    idTransportMode: 0,
    idOrigin: '',
    idDestination: '',
    originDestiny: [],
    originCityName: '',
    originCityId: '',
    destinationCityName: '',
    destinationCityId: '',
    idIncoterm: '',
    cargo: [{
            txCargo: '',
            isDangerous: true,
            idImoType: null,
            idTemperature: 0,
            idCargoContractingType: 1,
            cargoVolumes: [],
            vlCwPurchase: null,
            vlCwSale: null,
            idCargoDangerous: null
        }],
    transitTime: 0,
    idFrequency: 0,
    route: '',
    freeTimeDemurrages: [],
    recurrency: 1,
    weeklyRecurrency: '',
    transportIncluded: false,
    clearenceIncluded: false,
    agents: [],
    profits: [],
    cepCargoCollectionAddress: '',
    cepCargoDeliveryAddress: ''
};
export const ProposalContext = createContext(null);
export const ProposalContextProvider = ({ children }) => {
    const [proposal, setProposal] = useState(emptyProposalValue);
    const newProposal = useMemo(() => ({ proposal, setProposal }), [proposal, setProposal]);
    return React.createElement(ProposalContext.Provider, { value: newProposal }, children);
};
